export interface ICSVDataCell {
    error?: string
    value?: string | number
}

export interface IBulkUploadStatus {
    rows: (string | number)[]
    current: number
}

export const BULK_UPLOAD_FILE_EXTENSIONS = [".csv"]

// Params:
// bulkUploadFile -> File object
// successAction -> function to execute if the bulkUploadFile passes all verifications
export function verifyBulkUploadFile(
    bulkUploadFile: File | null | undefined,
    successAction: () => void,
    errorAction: (error: string) => void,
    fileChangeCallback: (file: File, successAction: () => void) => void
) {
    // Checks that an uploaded bulkUploadFile has
    // 1. Correct file extension
    // If it passes all checks, executes successAction (this is handled by the onBulkUploadFileChange method, retrieved from props)
    if (!bulkUploadFile) {
        errorAction("NO FILE FOUND")
        return
    }

    // TODO: Localize errors

    errorAction("")

    const fileNameAndExtension = RegExp(/\.([^.]+)$/).exec(bulkUploadFile?.name)
    const fileExtension = fileNameAndExtension ? fileNameAndExtension[1] : "."

    if (BULK_UPLOAD_FILE_EXTENSIONS.includes("." + fileExtension)) {
        successAction()
        fileChangeCallback(bulkUploadFile, successAction)
    } else {
        errorAction(`Unsupported Bulk Upload file format: (.${fileExtension})`)
    }
}

export const showError = (error: string) => {
    return error !== ""
}

export const handleFileDrop = (
    event: React.DragEvent<HTMLDivElement>,
    successAction: (file: File | null) => void,
    errorAction: (error: string) => void,
    fileChangeCallback: (file: File, successAction: () => void) => void
) => {
    const newBulkUploadFile = event?.dataTransfer?.files.item(0)
    verifyBulkUploadFile(
        newBulkUploadFile,
        () => successAction(newBulkUploadFile),
        errorAction,
        fileChangeCallback
    )
    event.preventDefault()
}

export const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    successAction: (file: File | null | undefined) => void,
    errorAction: (error: string) => void,
    fileChangeCallback: (file: File, successAction: () => void) => void
) => {
    const newBulkUploadFile = (event?.target as HTMLInputElement).files?.item(0)
    verifyBulkUploadFile(
        newBulkUploadFile,
        () => successAction(newBulkUploadFile),
        errorAction,
        fileChangeCallback
    )
    event.preventDefault()
}

export const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
}

export const downloadURL = (url: string, filename: string) => {
    fetch(url)
        .then((file) => file.blob())
        .then((blob) => {
            // Create an empty <a> element
            const anchor = document.createElement("a")
            anchor.href = window.URL.createObjectURL(blob)
            anchor.setAttribute("download", filename)
            anchor.click()
        })
}

export enum UploadDataType {
    Locations = "locations",
    RiskProfiles = "risk profiles",
}

export const modalStrings = {
    [UploadDataType.Locations]: {
        nextViewPath: "/admin/locations",
        NImportedSuccessfully: "NLocationsImportedSucessfully",
        emptyCSV: "noLocationsDetected",
        addAtLeastOne: "pleaseAddAtLeastOneLocation",
        importN: "importNLocations",
        doneImportingN: "doneImportingNLocations",
        thereWasAProblemWithN: "thereWasAProblemWithNLocations",
        modalDisplayTitle: "importLocations",
        modalDisplaySubtitle: "readingFile",
        closeThisWindow: "youCanCloseThisWindowAndReviewLocations",
        thisFileHasNoErrors: "thisFileHasNoErrors",
        importingNOfM: "importingNOfMLocations",
        back: "finishImportBtn",
    },
    [UploadDataType.RiskProfiles]: {
        emptyCSV: "noRiskProfilesDetected",
        addAtLeastOne: "pleaseAddAtLeastOneRiskProfile",
        modalDisplayTitle: "importRiskProfiles",
        modalDisplaySubtitle: "readingFile",
        closeThisWindow: "youCanCloseThisWindowAndReviewRiskProfiles",
        back: "finishImportBtn",
        nextViewPath: "/admin/risk-profiles",
        NImportedSuccessfully: "NRiskProfilesImportedSucessfully",
        importN: "importNRiskProfiles",
        doneImportingN: "doneImportingNRiskProfiles",
        thereWasAProblemWithN: "thereWasAProblemWithNRiskProfiles",
        thisFileHasNoErrors: "thisFileHasNoErrors",
        importingNOfM: "importingNOfMRiskProfiles",
    },
} as { [key in UploadDataType]: { [key: string]: string } }
