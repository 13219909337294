import { useTranslate } from "@tolgee/react"
import React from "react"
import SearchWidget from "../../climateui/components/Widgets/SeasonalWidgets/SearchWidget"

interface Props {
  title: string
  loading: boolean
  data?: {
    stages?: { stage_name: string }[]
    region?: { name: string }
  }
}

const YieldOutlookStageWidget: React.FC<Props> = ({ title, loading, data }) => {
  const { t } = useTranslate()

  const currentSeason = data?.stages?.[0]?.stage_name ?? ""
  const region = data?.region?.name ?? ""

  return (
    <SearchWidget
      title={title}
      value={t(currentSeason)}
      description={region}
      loading={loading}
      errorMessage={t("errorLoadingData")}
      reloadMessage={t("clickReload")}
      noResultsMessage={t("noResultCurrentFilters")}
      tryAnotherFilterMessage={t("tryAnotherFilter")}
    />
  )
}

export default YieldOutlookStageWidget
