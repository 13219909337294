import { useMemo } from "react"
import { useLocations } from "../../providers"
import { IDashboardLocation, IInsightsLocation } from "../../types"

export default function useDashboardVarieties(
  customLocations: IDashboardLocation[] | undefined,
  customLocationsDict: Record<string, string>,
  selectedLocations: string | string[],
  allLocationsDict: Record<string, IInsightsLocation>,
  isDefaultDashboard: boolean,
) {
  const { locationVarieties } = useLocations()

  let _selectedLocations: string[] = []

  if (selectedLocations && typeof selectedLocations === "string")
    _selectedLocations = [selectedLocations]
  else if (selectedLocations && Array.isArray(selectedLocations))
    _selectedLocations = [...selectedLocations]

  //
  const varietiesDict = useMemo(() => {
    const dict: Record<string, string> = {}
    // selected dashboard varieties
    const currentLocationVarietiesIds = new Set<string>()

    if (isDefaultDashboard) {
      Object.keys(allLocationsDict)
        .filter((loc) =>
          _selectedLocations.includes(allLocationsDict[loc].id as string),
        )
        .forEach((loc) => {
          allLocationsDict[loc].varieties?.forEach((v) => {
            currentLocationVarietiesIds.add(v.id)
          })
        })
      return Object.fromEntries(
        Object.entries(locationVarieties).filter(([key]) =>
          currentLocationVarietiesIds.has(key),
        ),
      )
    }
    customLocations
      ?.filter((loc) => _selectedLocations.includes(loc?.location_id ?? ""))
      .forEach((loc) =>
        loc.varieties?.forEach((v) => {
          currentLocationVarietiesIds.add(v.variety_id)
        }),
      )

    currentLocationVarietiesIds?.forEach((v) => {
      if (v) {
        dict[v] = locationVarieties[v]
      }
    })

    return dict
  }, [
    _selectedLocations,
    selectedLocations,
    customLocations,
    customLocationsDict,
    allLocationsDict,
  ])

  return { varietiesDict }
}
