import { useTranslate } from "@tolgee/react"
import { useContext, useState } from "react"
import { useMutation, useQueryClient } from "react-query"
import { useNavigate } from "react-router-dom"
import { TableActions } from "../../../../climateui/components/Table/tableUtils"
import {
  EditIcon,
  PinSettingsIcon,
  TrashIcon,
} from "../../../../climateui/icons"
import { ModalContext, ToastContext } from "../../../../climateui/providers"
import { useIsFlagEnabled } from "../../../../hooks"
import { useRiskProfiles } from "../../../../providers/RiskProfilesProvider"
import { IRiskProfile } from "../../../../types"
import { riskProfileQuerySet } from "../../../../utils/networking"

export const RiskProfileTableActions = ({
  rowData,
}: {
  rowData: IRiskProfile
}) => {
  const { t } = useTranslate()
  const navigate = useNavigate()
  const { confirmationModal } = useContext(ModalContext)
  const { enqueueAlert } = useContext(ToastContext)
  const { setCurrentRiskProfileById, setIsFormOpen } = useRiskProfiles()
  const isStageManagerEnabled = useIsFlagEnabled("feature_stage_manager")
  const [type, setType] = useState<string>("ds_defined")

  const queryClient = useQueryClient()
  const { mutateAsync: _deleteRiskProfile } = useMutation(
    async (id: string) => {
      await riskProfileQuerySet.delete(`/${id}`)
    },
    {
      onSuccess: () => {
        enqueueAlert(
          t("riskProfileDeleted", "Risk profile successfully deleted"),
        )
        queryClient.invalidateQueries(["riskProfiles"])
      },
    },
  )

  const editRiskProfile = (riskProfile: IRiskProfile) => {
    setCurrentRiskProfileById(riskProfile.id)
    setIsFormOpen(true)
    setType(riskProfile?.type || "ds_defined")
  }

  const deleteRiskProfile = (id: string) => {
    confirmationModal({
      title: t("riskProfileDeletion", "Risk Profile Deletion"),
      text: isStageManagerEnabled
        ? t(
            "actionWillDeleteAssociatedAlertsAndFromTimeline",
            "This action will delete associated alerts & remove risk profile from the timeline of the following assets: <b>{assets}</b>. Do you want to continue?",
            {
              assets: rowData.varieties
                .map((variety) => `${variety.asset.name} (${variety.name})`)
                .join(", "),
            },
          )
        : t(
            "actionWillDeleteAssociatedAlerts",
            "This action will delete the associated alerts. Do you want to continue?",
          ),
      onContinue: () => {
        _deleteRiskProfile(id)
      },
      onContinueLabel: t("continue"),
      onCancelLabel: t("cancel"),
    })
  }

  return (
    <TableActions
      rowData={rowData}
      actions={[
        {
          tooltip: t("addAlerts", "Set up alerts for locations"),
          icon: <PinSettingsIcon />,
          onClick: () => navigate(`/admin/risk-profiles/${rowData.id}/alerts`),
        },
        {
          tooltip: t("editRiskProfile", "Edit Risk Profile"),
          icon: <EditIcon />,
          onClick: () => editRiskProfile(rowData),
        },
        {
          tooltip: t("deleteRiskProfile", "Delete Risk Profile"),
          icon: <TrashIcon />,
          onClick: () => deleteRiskProfile(rowData.id),
        },
      ]}
    />
  )
}
