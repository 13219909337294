import { useTranslate } from "@tolgee/react"
import { DateTime, Interval } from "luxon"
import { IFullTrigger, IHazardProfile } from "../../types"
import { createGraphQLProxy } from "../../utils/networking"
import { CalendarTimelineItem } from "../types/widget"

export const getGranularityArray = (
    startingDate: DateTime,
    granularity: string,
    numDays: number,
    numWeeks: number,
    increment: number
) => {
    const shownDates: string[] = []
    const granularityArray: DateTime[] = []
    granularityArray.push(startingDate.startOf("day"))

    if (granularity == "daily") {
        shownDates.push(
            startingDate.toLocaleString({
                weekday: "short",
                day: "numeric",
            })
        )
        for (let d = 1; d < numDays; d += increment) {
            const date = startingDate.plus({ days: d })
            granularityArray.push(date.startOf("day"))
            shownDates.push(
                date.toLocaleString({ weekday: "short", day: "numeric" })
            )
        }
    } else if (granularity == "weekly") {
        shownDates.push(
            startingDate
                .startOf("week")
                .toLocaleString({ month: "short", day: "numeric" }) +
                " - " +
                startingDate
                    .endOf("week")
                    .toLocaleString({ month: "short", day: "numeric" })
        )
        for (let w = 1; w < numWeeks; w += increment) {
            const date = startingDate.plus({ weeks: w })
            granularityArray.push(date.startOf("week"))
            shownDates.push(
                date
                    .startOf("week")
                    .toLocaleString({ month: "short", day: "numeric" }) +
                    " - " +
                    date
                        .endOf("week")
                        .toLocaleString({ month: "short", day: "numeric" })
            )
        }
    } else {
        shownDates.push(startingDate.monthLong)
        for (let m = 1; m <= 6; m += increment) {
            const date = startingDate.plus({ months: m })
            granularityArray.push(date.startOf("month"))
            shownDates.push(date.monthLong)
        }
    }
    return { granularityArray, shownDates }
}

const getConditionalDescription = (conditionalSymbol: string) => {
    switch (conditionalSymbol) {
        case ">":
            return "considerablyAbove"
        case "<":
            return "considerablyBelow"
        default:
            return "at"
    }
}

export const getCalendarAlertInfo = (
    item: CalendarTimelineItem,
    startingDate: DateTime,
    endDate: DateTime
) => {
    const { t } = useTranslate()
    const AlertIcon = item.icon
    let fitsCompleteAlert = true
    let startsBeforeToday = false
    let alertCategory = null

    const itemHazardProfiles = createGraphQLProxy(
        (item.alertInfo.processing_run as IFullTrigger).risk_profile
    ).hazard_profiles

    const alertRiskProfile = item.alertInfo.processing_run.risk_profile

    const alertShortDescription: string =
        itemHazardProfiles
            ?.map((profile: IHazardProfile) => {
                // const hazardType = profile?.type
                const conditional = profile?.conditional
                const threshold = profile?.threshold
                const units = profile?.hazard_variable.units ?? ""
                if (alertRiskProfile?.type === "ds_defined") {
                    return `${profile?.hazard_variable.readable_name} ${t(
                        getConditionalDescription(conditional)
                    )}`
                }
                return `${
                    profile?.hazard_variable.readable_name
                } ${conditional} ${threshold + units}`
            })
            .join(", ") ?? ""

    if (item.endDate >= endDate) {
        item.endDate = endDate
        fitsCompleteAlert = false
    }
    const leftSpace = Interval.fromDateTimes(
        startingDate,
        item.startDate.startOf("day")
    ).length("days")

    let alertDaysLength =
        Interval.fromDateTimes(
            item.startDate.startOf("day"),
            item.endDate.startOf("day")
        ).length("days") + 1

    if (item.startDate < startingDate) {
        startsBeforeToday = true
        alertDaysLength =
            alertDaysLength -
            Interval.fromDateTimes(
                item.startDate.startOf("day"),
                startingDate
            ).length("days")
    }

    if (item.alertInfo.category) {
        alertCategory = item.alertInfo.category.toLowerCase()
    }

    return {
        AlertIcon,
        alertCategory,
        alertShortDescription,
        fitsCompleteAlert,
        leftSpace,
        alertDaysLength,
        startsBeforeToday,
    }
}
