import { DateTime } from "luxon"
import React, { useMemo } from "react"
import {
  Line,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
  ReferenceLine,
  Label,
  Legend,
} from "recharts"
import LoadingAnimation from "../../../../climateui/components/LoadingAnimation"
import renderRiskChartReferences from "./renderRiskChartReferences"
import renderCustomLegend from "./renderCustomLegend"
import { Stage } from "../types"
import { AxisInterval } from "recharts/types/util/types"
import { getProbabilityHoverItems } from "../riskOutlookUtils"
import ProbabilityHover from "../../../../climateui/components/Widgets/SeasonalWidgets/ProbabilityHover"
import renderRiskChartStages from "./renderRiskChartStages"
import RiskOutlookChartHeader from "./RiskOutlookChartHeader"
import RiskOutlookIcon from "../../../../climateui/icons/variableIcons/RiskOutlookIcon"

interface Point {
  id: string
  date: string
  y?: number
  y0?: number
  y1?: number
}

interface Plot {
  id: string
  visualization: "line" | "area"
  color: string
  points: Point[]
}

interface LegendInterface {
  type: "line" | "area" | "dot" | "line-dashed"
  label: string
  color: string
}

interface RiskOutlookChartProps {
  data: {
    plot: Plot[]
  }
  isHistorical?: boolean
  stages: Stage[]
  dottedSeparatorDate?: string
  highlightedDate?: string
  verticalLineDate?: string
  verticalLineColor?: string
  showDots?: boolean
  adjustYAxis?: boolean
  domain?: [number, number]
  xTickFormatter?: (value: { date: string }) => string
  xAxisInterval?: AxisInterval | number
  loading?: boolean
  lowerBound?: number
  upperBound?: number
  legends?: LegendInterface[]
  xLabel?: string
  description?: string
  tooltip?: string
  title?: string
  icon?: string
}

const RiskOutlookChart: React.FC<RiskOutlookChartProps> = ({
  data,
  isHistorical,
  stages,
  dottedSeparatorDate,
  highlightedDate,
  verticalLineDate,
  verticalLineColor = "#000000",
  showDots = true,
  adjustYAxis = true,
  domain,
  xTickFormatter,
  xAxisInterval,
  loading,
  lowerBound,
  upperBound,
  legends,
  xLabel,
  description,
  tooltip,
  title,
  icon,
}) => {
  const transformedData = useMemo(() => {
    if (!data?.plot || data.plot?.length === 0) {
      return []
    }

    return data.plot.flatMap((plot) => {
      const points = plot.points.reduce((acc: Point[], point, index) => {
        // Add current point
        acc.push({
          ...point,
          [plot.id]:
            plot.visualization === "area" ? [point.y0, point.y1] : point.y,
        })

        // Check if there's a next point and if there's a gap
        if (index < plot.points.length - 1) {
          const currentDate = DateTime.fromFormat(point.date, "dd-MM-yyyy")
          const nextDate = DateTime.fromFormat(
            plot.points[index + 1].date,
            "dd-MM-yyyy",
          )
          const diffInDays = nextDate.diff(currentDate, "days").days

          // If there's a gap of more than 1 day
          if (diffInDays > 1) {
            // Add a copy of the current point for the next day
            acc.push({
              ...point,
              date: currentDate.plus({ days: 1 }).toFormat("dd-MM-yyyy"),
              [plot.id]:
                plot.visualization === "area" ? [point.y0, point.y1] : point.y,
            })
          }
        }

        return acc
      }, [])

      return points
    })
  }, [data])

  if (loading) {
    return (
      <div className="flex justify-center items-center mt-36 body-lg">
        <LoadingAnimation />
      </div>
    )
  }

  if (transformedData.length === 0) {
    return <div>No data points available for the chart.</div>
  }

  return (
    <div className="w-full bg-white border rounded-lg font-roboto border-1 border-gray-14 p-[14px]">
      <RiskOutlookChartHeader
        title={title}
        tooltip={tooltip}
        icon={icon}
        description={description}
      />
      <div>
        <ResponsiveContainer
          width="100%"
          height={400}>
          <ComposedChart
            data={transformedData}
            margin={{ left: 50, bottom: stages ? 50 : 0 }}>
            <Legend
              verticalAlign="top"
              align="right"
              layout="horizontal"
              content={() => renderCustomLegend({ legends })}
            />
            <XAxis
              allowDataOverflow
              axisLine={false}
              tickLine={false}
              padding={{ right: 5, left: 5 }}
              dataKey="date"
              type="category"
              allowDuplicatedCategory={false}
              strokeWidth={0}
              interval={xAxisInterval ?? 0}
              fontSize={14}
              tickFormatter={(value) => {
                return xTickFormatter ? xTickFormatter(value) : value
              }}>
              {xLabel && (
                <Label
                  value={xLabel}
                  position="insideBottom"
                />
              )}
            </XAxis>

            <YAxis
              padding={{ top: 10, bottom: 10 }}
              domain={
                adjustYAxis
                  ? [
                      (dataMin: number) =>
                        Math.min(
                          dataMin,
                          ...transformedData.flatMap((point) =>
                            Object.values(point).filter(
                              (value): value is number =>
                                typeof value === "number",
                            ),
                          ),
                        ) ?? 0,
                      (dataMax: number) =>
                        Math.max(
                          dataMax,
                          ...transformedData.flatMap((point) =>
                            Object.values(point).filter(
                              (value): value is number =>
                                typeof value === "number",
                            ),
                          ),
                        ) ?? 0,
                    ]
                  : domain
              }
              axisLine={false}
              tickLine={false}
              strokeWidth={0}
              tick={false}
            />

            {data.plot.map((plot) => {
              if (plot.visualization === "line") {
                return (
                  <React.Fragment key={`fragment_${plot.id}`}>
                    <Line
                      type="linear"
                      dataKey={plot.id}
                      stroke={plot.color}
                      strokeWidth={2}
                      dot={
                        showDots
                          ? (props) => {
                              const { cx, cy, payload } = props

                              if (
                                DateTime.fromISO(payload.date)
                                  .startOf("day")
                                  .equals(
                                    DateTime.fromISO(
                                      highlightedDate ?? "",
                                    ).startOf("day"),
                                  )
                              ) {
                                return (
                                  <circle
                                    cx={cx}
                                    cy={cy}
                                    r={5}
                                    fill={plot.color}
                                  />
                                )
                              }
                              return (
                                <circle
                                  cx={cx}
                                  cy={cy}
                                  r={2}
                                  fill={plot.color}
                                  opacity={0.5}
                                />
                              )
                            }
                          : false
                      }
                      strokeDasharray={"0"}
                      data={transformedData.filter((point) => {
                        return (
                          !dottedSeparatorDate ||
                          DateTime.fromFormat(point.date, "dd-MM-yyyy") <=
                            DateTime.fromISO(dottedSeparatorDate ?? "")
                        )
                      })}
                    />
                    {dottedSeparatorDate && (
                      <Line
                        type="linear"
                        dataKey={plot.id}
                        stroke={plot.color}
                        dot={
                          showDots
                            ? (props) => {
                                const { cx, cy, payload } = props
                                if (payload.date === highlightedDate) {
                                  return (
                                    <circle
                                      cx={cx}
                                      cy={cy}
                                      r={5}
                                      fill={plot.color}
                                    />
                                  )
                                }
                                return (
                                  <circle
                                    cx={cx}
                                    cy={cy}
                                    r={2}
                                    fill={plot.color}
                                  />
                                )
                              }
                            : false
                        }
                        strokeWidth={2}
                        strokeDasharray="3 3"
                        data={transformedData.filter((point) => {
                          return (
                            DateTime.fromFormat(
                              point.date,
                              "dd-MM-yyyy",
                            ).toMillis() >=
                            DateTime.fromISO(
                              dottedSeparatorDate ?? "",
                            ).toMillis()
                          )
                        })}
                      />
                    )}
                  </React.Fragment>
                )
              } else if (plot.visualization === "area") {
                return (
                  <Area
                    dot={false}
                    key={plot.id}
                    type="linear"
                    dataKey={plot.id}
                    stroke={"none"}
                    fill={plot.color}
                    fillOpacity={0.3}
                  />
                )
              }
              return null
            })}
            <Tooltip
              allowEscapeViewBox={{ y: true }}
              isAnimationActive={false}
              wrapperStyle={{
                zIndex: 1000,
              }}
              formatter={(value, name) => {
                if (typeof value === "number") {
                  return [value.toFixed(2), name]
                }
                return [value, name]
              }}
              content={({ payload }) => {
                const date = payload?.[0]?.payload?.date
                const yMin = payload?.[0]?.payload?.yMin
                const yMid = payload?.[0]?.payload?.yMid
                const yMax = payload?.[0]?.payload?.yMax
                const value = payload?.[0]?.payload?.y
                const seasonLabel =
                  payload?.[0]?.payload?.meta?.season_label ?? "Not available"

                if (isHistorical && date != highlightedDate) {
                  return (
                    <div className="bg-white p-2.5 rounded shadow border border-gray-10">
                      <ProbabilityHover
                        title=""
                        rightTitle={seasonLabel}
                        leftTitle={"Season"}
                        items={[
                          {
                            title: "Severity",
                            description: "Historical",
                            value: value?.toFixed(2) ?? "",
                            icon: () => <RiskOutlookIcon />,
                          },
                        ]}
                      />
                    </div>
                  )
                }

                return (
                  <div className="bg-white p-2.5 rounded shadow border border-gray-10">
                    <ProbabilityHover
                      title="Probability Breakdown by Severity"
                      leftHeader="Severity"
                      rightHeader={value?.toFixed(2) ?? ""}
                      leftTitle={isHistorical ? "Season" : "Date"}
                      rightTitle={isHistorical ? seasonLabel : date}
                      items={getProbabilityHoverItems(
                        yMin ?? 0,
                        yMid ?? 0,
                        yMax ?? 0,
                        lowerBound ?? 0,
                        upperBound ?? 0,
                      )}
                    />
                  </div>
                )
              }}
            />

            {renderRiskChartReferences({
              lowerBound: lowerBound ?? 70,
              upperBound: upperBound ?? 90,
            })}
            {verticalLineDate && (
              <ReferenceLine
                x={DateTime.fromISO(verticalLineDate).toFormat("dd-MM-yyyy")}
                stroke={verticalLineColor}
                strokeWidth={2}>
                <Label
                  position={"bottom"}
                  offset={7}
                  fontWeight={500}
                  fontSize={14}>
                  Today
                </Label>
              </ReferenceLine>
            )}

            {/* Stages */}
            {stages && renderRiskChartStages(stages, icon)}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

export default RiskOutlookChart
