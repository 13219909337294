import { DAY_MS } from "./constants"

export const TODAY = new Date()

export const FIRST_DAY_OF_YEAR = new Date(TODAY.getFullYear(), 0, 1)

export const TODAY_1900 = new Date(1900, TODAY.getMonth(), TODAY.getDate())

export const FIRST_DAY_OF_YEAR_1900 = new Date(1900, 0, 1)

export function daysBetween(d1: Date, d2: Date) {
    // Ignore time of dates
    const aux1 = new Date(d1.getFullYear(), d1.getMonth(), d1.getDate())
    const aux2 = new Date(d2.getFullYear(), d2.getMonth(), d2.getDate())
    const diff = aux2.getTime() - aux1.getTime()
    return Math.ceil(diff / DAY_MS)
}

export const compareDates = (date1: Date, date2: Date) => {
    const date1S = date1.getTime()
    const date2S = date2.getTime()

    if (date1S < date2S) return -1
    else if (date1S > date2S) return 1
    else return 0
}

// yearAgnosticDateString = "MM-DD"
export const newDateFromYearAgnosticDateString = (
    yearAgnosticDateString: string,
    referenceDate = TODAY_1900
) => {
    const [mm, dd] = yearAgnosticDateString.split("-")
    return new Date(referenceDate.getFullYear(), +mm - 1, +dd)
}

export const newStartAndEndDatesFromYearAgnosticDateString = (
    startDateString: string,
    endDateString: string,
    referenceDate?: Date
) => {
    const startDate = newDateFromYearAgnosticDateString(
        startDateString,
        referenceDate
    )
    const endDate = newDateFromYearAgnosticDateString(
        endDateString,
        referenceDate
    )

    if (compareDates(endDate, startDate) === -1)
        endDate.setFullYear(endDate.getFullYear() + 1)

    if (referenceDate && compareDates(endDate, referenceDate) === -1) {
        startDate.setFullYear(startDate.getFullYear() + 1)
        endDate.setFullYear(endDate.getFullYear() + 1)
    }

    return [startDate, endDate]
}

export const formatYearAgnosticDate = (date?: Date, emptyValue = "") => {
    if (!date) return emptyValue
    const mm = date.getMonth() + 1
    const dd = date.getDate()

    return (mm < 10 ? "0" : "") + mm + "-" + (dd < 10 ? "0" : "") + dd
}

export const NOON = { hour: 12, minute: 0, second: 0, millisecond: 0 }

export const formatDateLocalizedMonthNames = (
    date: Date,
    monthNames: string[]
) => {
    const monthIndex = date.getMonth()
    const dateDate = date.getDate()
    const localizedMonthFirst3 = monthNames[monthIndex].slice(0, 3)
    return localizedMonthFirst3 + " " + dateDate
}
