import EmptyModal from "../../../../climateui/providers/Modal/EmptyModal"
import {
    Dispatch,
    LegacyRef,
    SetStateAction,
    useEffect,
    useMemo,
    useState,
} from "react"
import {
    ITimelineRiskProfileStage,
    TimelineStageInput,
    IVariety,
    VarietyMetadataInput,
    varietyMetadataToInput,
    VARIETY_EMPTY_INITIAL_DATE,
} from "../../../../types"
import { useTranslate } from "@tolgee/react"
import {
    CancelIcon,
    LoadingCircleIcon,
    TrashIcon,
} from "../../../../climateui/icons"
import {
    Button,
    LabelAndInput,
    TooltipV2,
} from "../../../../climateui/components"
import {
    SingleDropdownSelect,
    GenericInput,
} from "../../../../climateui/components/Inputs"
import VarietyAttributesForm from "./VarietyAttributesForm"
import { useAssets } from "../../../../providers"
import { useAccount } from "../../../../providers/AccountProvider"
import { CAIResponse, isValidResponse } from "../../../../climateui/utils/http"
import DeleteVarietyModal from "./DeleteVarietyModal"
import { StartDateInput } from "../../../../components"
import { useNavigate } from "react-router-dom"
import { useToast } from "../../../../climateui/providers/Toast/ToastContextProvider"
import {
    formatYearAgnosticDate,
    newDateFromYearAgnosticDateString,
} from "../../../../utils/dates"
import { useIsFlagEnabled } from "../../../../hooks"
import useVarietyTimelineQuery from "../../../../hooks/useVarietyTimelineQuery"
import { stageQuerySet } from "../../../../utils/networking"

const VarietyForm = ({
    modalRef,
    isOpen,
    setIsOpen,
}: {
    modalRef?: LegacyRef<HTMLDivElement>
    isOpen: boolean
    setIsOpen: Dispatch<SetStateAction<boolean>>
}) => {
    /* HOOKS > START */
    const { t } = useTranslate()
    const navigate = useNavigate()
    const { enqueueAlert } = useToast()
    const {
        assets,
        varieties,
        defaultAttributes,
        addVariety,
        editVariety,
        selectedVariety,
        setSelectedVariety,
    } = useAssets()
    const { selectedAccount } = useAccount()
    const canViewInitialDateInput = useIsFlagEnabled("feature_stage_manager")
    /* HOOKS < END */

    /* STATE > START */
    const assetOptions = useMemo(() => {
        if (!assets) return {}
        return Object.entries(assets)
            .sort(([, assetA], [, assetB]) => {
                if (assetA.name > assetB.name) return 1
                if (assetA.name < assetB.name) return -1
                return 0
            })
            .reduce(
                (prev: Record<string, string>, [id, asset]) => ({
                    ...prev,
                    [id]: asset.name,
                }),
                {}
            )
    }, [assets])
    const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false)

    /* STATE > FORM > START */
    const [assetId, setAssetId] = useState<string | undefined | null>()
    const [varietyId, setVarietyId] = useState<string | undefined | null>()
    const [varietyName, setVarietyName] = useState<string>()
    const [description, setDescription] = useState<string>()
    const [startDay, setStartDay] = useState<Date>()
    const [varietyMetadata, setVarietyMetadata] = useState<
        VarietyMetadataInput[]
    >([])
    /* STATE < FORM < END */
    const [isSaving, setIsSaving] = useState(false)
    /* STATE < END */

    const { data, isLoading, isFetching } = useVarietyTimelineQuery(
        varietyId ?? undefined
    )

    /* METHODS > START */
    const prepopulateTimelineIfRequired = async (
        newVarietyId?: string
    ): Promise<CAIResponse> => {
        if (
            !data ||
            isLoading ||
            isFetching ||
            !isValidResponse(data) ||
            !newVarietyId
        ) {
            console.info("Not able to prepopulate variety timeline")
            return
        }

        const copyVarietyTimeline = data.data
        for (const stage of copyVarietyTimeline.stages) {
            const newStage: TimelineStageInput = {
                ...stage,
                variety_id: newVarietyId,
                risk_profile_ids: stage.stage_risk_profiles.map(
                    (rps: ITimelineRiskProfileStage) => rps.risk_profile_id
                ),
            }
            delete newStage.id
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            delete (newStage as any).stage_risk_profiles
            await stageQuerySet.post({
                path: "",
                data: newStage,
            })
        }
    }

    const cleanUp = async (response?: CAIResponse) => {
        // Don't clean after unsuccessful requests
        if (!response || !isValidResponse(response)) return

        if (!selectedVariety) {
            const newVariety = response.data[0]
            await prepopulateTimelineIfRequired(newVariety.id)
        }
        setIsSaving(false)
        setIsOpen(false)

        const newOrEditedVariety = response.data[0] as IVariety
        if (!selectedVariety && canViewInitialDateInput) {
            if (
                newOrEditedVariety.default_initial_date &&
                newOrEditedVariety.default_initial_date !==
                    VARIETY_EMPTY_INITIAL_DATE
            )
                navigate(newOrEditedVariety.id + "/timeline")
            else
                enqueueAlert(
                    t(
                        "editAssetInitialDateToAccessTimeline",
                        "Please edit the asset and add an initial date in order to access the Timeline"
                    )
                )
        }
    }

    const formIsValid =
        !!selectedAccount &&
        !!assetId &&
        !!varietyName &&
        varietyMetadata.every(({ value }) => !!value) &&
        !isLoading &&
        !isFetching
    const save = () => {
        // If there is no name, asset, or account set, just exit
        if (!formIsValid) return
        setIsSaving(true)
        const variety = {
            asset_id: assetId,
            account_id: selectedAccount,
            name: varietyName,
            description: description ?? "",
            default_initial_date: formatYearAgnosticDate(
                startDay,
                VARIETY_EMPTY_INITIAL_DATE
            ),
            variety_metadata: varietyMetadata,
        }
        if (selectedVariety) {
            editVariety({
                id: selectedVariety.id,
                variety,
            }).then(cleanUp)
        } else {
            addVariety(variety).then(cleanUp)
        }
    }
    /* METHODS < END */

    // Load selected variety for edition
    useEffect(() => {
        if (!selectedVariety) return // nothing to do...
        setAssetId(selectedVariety.asset_id)
        setVarietyName(selectedVariety.name)
        setDescription(selectedVariety.description)
        if (
            canViewInitialDateInput &&
            selectedVariety.default_initial_date &&
            selectedVariety.default_initial_date !== VARIETY_EMPTY_INITIAL_DATE
        ) {
            setStartDay(
                newDateFromYearAgnosticDateString(
                    selectedVariety.default_initial_date
                )
            )
        }
        setVarietyMetadata(
            selectedVariety.variety_metadata.map(varietyMetadataToInput)
        )
        setIsOpen(true)
    }, [selectedVariety])

    const clearForm = () => {
        // Clear the selected variety
        setSelectedVariety(undefined)
        setAssetId(undefined)
        setVarietyId(undefined)
        setVarietyName(undefined)
        setDescription(undefined)
        setStartDay(undefined)
        setVarietyMetadata([])
    }

    // Clear on form close
    useEffect(() => {
        if (isOpen) return // nothing to do...
        clearForm()
    }, [isOpen])

    const assetVarietiesOptions = useMemo(() => {
        if (selectedVariety || !assetId || !varieties) return {}

        const assetVarietiesOptions = Object.values(varieties).filter(
            (variety) => {
                return variety.asset_id === assetId
            }
        )
        const assetDefaultVariety = assetVarietiesOptions.find(
            (variety) => variety.asset_id === assetId && variety.is_default
        )
        if (assetDefaultVariety) setVarietyId(assetDefaultVariety.id)
        else console.error("No default variety for asset: ", assetId)

        return assetVarietiesOptions.reduce(
            (acc, curr) => ({
                ...acc,
                [curr.id]: `${curr.asset.name} (${curr.name})`,
            }),
            {}
        )
    }, [varieties, assetId])

    useEffect(() => {
        if (!varietyId || !varieties) {
            setStartDay(undefined)
            setVarietyMetadata([])
            return
        }

        const variety = varieties[varietyId]
        if (!variety) return

        if (
            canViewInitialDateInput &&
            variety.default_initial_date &&
            variety.default_initial_date !== VARIETY_EMPTY_INITIAL_DATE
        )
            setStartDay(
                newDateFromYearAgnosticDateString(variety.default_initial_date)
            )
        else setStartDay(undefined)

        if (variety.variety_metadata.length > 0)
            setVarietyMetadata(
                variety.variety_metadata.map(varietyMetadataToInput)
            )
        else setVarietyMetadata([])
    }, [varietyId, varieties])

    return (
        <EmptyModal
            customClasses="relative rounded-lg bg-white w-[80vw] lg:w-[642px] min-h-[360px] overflow-hidden max-h-[420px] flex flex-col"
            modalRef={modalRef}
            open={isOpen}>
            {/* HEADER */}
            <div className="flex justify-between p-4 border-b grow-0 border-gray-10 shrink-0">
                <h1 className="title-sm">
                    {selectedVariety
                        ? t("editAssetVariety", "Edit Asset Variety")
                        : t("newAssetVariety", "New Asset Variety")}
                </h1>
                {/* HEADER ACTIONS */}
                <div className="flex fill-gray-60 gap-3">
                    {selectedVariety && !selectedVariety.is_default && (
                        <div
                            className="w-6 h-6 cursor-pointer"
                            onClick={() => setIsDeleteOpen(true)}>
                            <TrashIcon />
                        </div>
                    )}
                    <div
                        className="w-6 h-6 cursor-pointer"
                        onClick={() => setIsOpen(false)}>
                        <CancelIcon />
                    </div>
                </div>
            </div>
            {/* BODY */}
            <div className="flex flex-row flex-wrap p-4 overflow-auto gap-3 grow">
                <div
                    className={[
                        "flex flex-col gap-2 flex-1 min-w-[200px]",
                        "[&_.ddBox]:h-[42px]",
                        "[&_.ddBox]:w-full",
                        "[&_.ddBox]:text-left",
                    ].join(" ")}>
                    <LabelAndInput
                        label={t("asset", "Asset")}
                        input={
                            <SingleDropdownSelect
                                options={assetOptions}
                                selected={assetId}
                                setSelected={setAssetId}
                                disabled={!!selectedVariety}
                                canSearch={!!assets}
                                searchMin={5}
                            />
                        }
                    />
                    {!selectedVariety && (
                        <TooltipV2
                            position="right"
                            content={
                                canViewInitialDateInput
                                    ? t(
                                          "varietyPrepopulateFromTooltip",
                                          "This will prepopulate start day, attributes and the timeline of your new asset."
                                      )
                                    : t(
                                          "varietyPrepopulateFromTooltipNoSM",
                                          "This will prepopulate the attributes of your new asset."
                                      )
                            }
                            contentClass="w-36"
                            containerClasses="h-fit w-full grow-0 shrink-0">
                            <LabelAndInput
                                label={
                                    t("prepopulateFrom", "Prepopulate from") +
                                    " " +
                                    t("(optional)")
                                }
                                input={
                                    <SingleDropdownSelect
                                        options={assetVarietiesOptions}
                                        selected={varietyId}
                                        setSelected={setVarietyId}
                                        disabled={
                                            !assetId ||
                                            Object.keys(assetVarietiesOptions)
                                                .length === 0
                                        }
                                        canSearch={!!assets}
                                        searchMin={5}
                                        canClear
                                    />
                                }
                            />
                        </TooltipV2>
                    )}
                    <LabelAndInput
                        label={t("varietyName", "Variety name")}
                        input={
                            <GenericInput
                                value={varietyName}
                                handleChange={(ev) =>
                                    setVarietyName(ev.target.value)
                                }
                            />
                        }
                    />
                    <LabelAndInput
                        label={t("description", "Description")}
                        input={
                            <GenericInput
                                value={description}
                                handleChange={(ev) =>
                                    setDescription(ev.target.value)
                                }
                            />
                        }
                    />
                </div>
                <div className="flex flex-col gap-2 flex-1 min-w-[200px]">
                    {canViewInitialDateInput && (
                        <LabelAndInput
                            label={t("defaultStartDay", "Default start day")}
                            input={
                                <StartDateInput
                                    isYearAgnostic
                                    date={startDay}
                                    canClearInput={false}
                                    setDate={setStartDay}
                                />
                            }
                        />
                    )}
                    <LabelAndInput
                        label={t("attributes", "Attributes")}
                        input={
                            <VarietyAttributesForm
                                initialAttributes={varietyMetadata}
                                options={defaultAttributes}
                                onChange={setVarietyMetadata}
                            />
                        }
                    />
                </div>
            </div>
            {/* FOOTER */}
            <div className="flex justify-end px-4 py-2 border-t grow-0 border-gray-10 shrink-0">
                <Button
                    onClick={save}
                    icon={
                        isSaving ? (
                            <div className="animate-spin">
                                <LoadingCircleIcon />
                            </div>
                        ) : undefined
                    }
                    disabled={!formIsValid || isSaving}
                    label={t("save", "Save")}
                />
            </div>
            {/* DELETE VARIETY MODAL */}
            {selectedVariety && (
                <DeleteVarietyModal
                    isOpen={isDeleteOpen}
                    setIsOpen={setIsDeleteOpen}
                    afterDeleteCallback={() => setIsOpen(false)}
                    variety={selectedVariety}
                />
            )}
        </EmptyModal>
    )
}
export default VarietyForm
