import { useContext, useMemo } from "react"
import { LocationsContext } from "../../providers/LocationsProvider"
import { IDashboardLocation, IInsightsLocation } from "../../types"
import { arrToDict } from "../../utils/transform"

export default function useDashboardLocations(
  dashboardLocations: IDashboardLocation[] | undefined,
  showAllLocations?: boolean,
) {
  const { locations, childLocations, loadingLocations } =
    useContext(LocationsContext)

  // all user locations
  const { allLocations, allLocationsDict } = useMemo(() => {
    let result = [...locations]
    if (childLocations) result = result.concat(childLocations)

    return {
      allLocations: result,
      allLocationsDict: arrToDict(result, "id"),
    }
  }, [locations, childLocations])

  // dashboard locations
  const customLocations = [] as IInsightsLocation[]
  const customLocationsDict: Record<string, string> = {}
  let locationIdsIterator: string[]

  // if is default dashboard, show all locations as options
  if (showAllLocations)
    locationIdsIterator = allLocations.map((loc) => loc.id ?? "")
  else
    locationIdsIterator =
      dashboardLocations?.map((loc) => loc.location_id ?? "") ?? []

  locationIdsIterator.forEach((id) => {
    if (id && allLocationsDict[id]?.id) {
      customLocations.push(allLocationsDict[id])
      customLocationsDict[id] = allLocationsDict[id].name
    }
  })

  return {
    // default
    locations,
    childLocations,
    loadingLocations,
    //
    allLocations,
    allLocationsDict,
    // dashboard locations (linked)
    dashboardLocations,
    // custom locations (linked OR all)
    customLocations,
    customLocationsDict,
    // selectedLocation,
    // setSelectedLocation,
  }
}
