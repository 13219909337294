import { useTranslate } from "@tolgee/react"
import { useEffect, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Button, TooltipV2 } from "../../../../climateui/components"
import { AsteriskIcon, CancelIcon } from "../../../../climateui/icons"
import { RiskIconHandler } from "../../../../climateui/icons/riskIcons"
import { useModal } from "../../../../climateui/providers/Modal/ModalContextProvider"
import {
    CustomResponse,
    isValidResponse,
} from "../../../../climateui/utils/http"
import { Timeline } from "../../../../components"
import useVarietyTimelineQuery from "../../../../hooks/useVarietyTimelineQuery"
import { StepsNavigationLayout } from "../../../../layouts"
import { useAssets } from "../../../../providers"
import {
    IRiskProfile,
    riskProfileToInput,
    ITimelineRow,
    TimelineStageInput,
    TIMELINE_EDITION_ACTIONS,
    IVariety,
    VARIETY_EMPTY_INITIAL_DATE,
} from "../../../../types"
import { buildTimelineStagesFromVarietyStages } from "./utils"
import {
    isSameRiskProfile,
    useRiskProfiles,
} from "../../../../providers/RiskProfilesProvider"
import {
    daysBetween,
    FIRST_DAY_OF_YEAR_1900,
    newDateFromYearAgnosticDateString,
} from "../../../../utils/dates"
import { useToast } from "../../../../climateui/providers/Toast/ToastContextProvider"
import queryClient, {
    riskProfileQuerySet,
    stageQuerySet,
} from "../../../../utils/networking"
import { useAccount } from "../../../../providers/AccountProvider"
import { arrToDict } from "../../../../utils/transform"
import { useMutation } from "react-query"
import FullScreen from "../../../../components/FullScreen"

function VarietyTimeline() {
    const { t } = useTranslate()
    const navigate = useNavigate()
    const { selectedAccount } = useAccount()
    const {
        varieties,
        caiDefaultVarieties,
        isLoadingVarieties,
        isFetchingVarieties,
    } = useAssets()
    const { riskProfilesObj, riskProfiles, loadingRisks } = useRiskProfiles()
    const { confirmationModal } = useModal()
    const { enqueueAlert } = useToast()
    const { varietyId } = useParams()

    const [saving, setSaving] = useState(false)

    const { mutateAsync: createStage } = useMutation({
        mutationFn: (stage: TimelineStageInput) =>
            stageQuerySet.post("/", stage),
    })
    const { mutateAsync: updateStage } = useMutation({
        mutationFn: (stage: TimelineStageInput) =>
            stageQuerySet.put(`/${stage.id}`, stage),
    })
    const { mutateAsync: deleteStage } = useMutation({
        mutationFn: (stageId: string) => stageQuerySet.delete(`/${stageId}`),
    })
    const selectedVariety = varietyId ? varieties?.[varietyId] : undefined

    // NAVIGATION GUARDS
    useEffect(() => {
        if (isLoadingVarieties || isFetchingVarieties) return
        if (varietyId && varieties && !varieties[varietyId]) {
            enqueueAlert(
                t("noVarietyWithId", "There is no variety with that id")
            )
            navigate("/admin/assets")
        }
    }, [isLoadingVarieties, isFetchingVarieties, varietyId, varieties])

    useEffect(() => {
        if (!selectedVariety) return
        if (
            selectedVariety.default_initial_date === VARIETY_EMPTY_INITIAL_DATE
        ) {
            enqueueAlert(
                t(
                    "editAssetInitialDateToAccessTimeline",
                    "Please edit the asset and add an initial date in order to access the Timeline"
                )
            )
            navigate("/admin/assets")
        }
    }, [selectedVariety])

    let varietyName = ""
    if (selectedVariety)
        varietyName = `${selectedVariety.asset.name} (${selectedVariety.name})`

    // GET DEFAULTS (ACC and CAI) FOR PREPOPULATION
    const { caiDefaultVariety, accountDefaultVariety } = useMemo(() => {
        const assetId = selectedVariety?.asset.id
        const result = {
            caiDefaultVariety: undefined,
            accountDefaultVariety: undefined,
        } as {
            caiDefaultVariety?: IVariety
            accountDefaultVariety?: IVariety
        }
        if (!assetId) return result
        if (varieties)
            result.accountDefaultVariety = Object.values(varieties).find(
                (variety) => variety.asset_id === assetId && variety.is_default
            )
        if (caiDefaultVarieties)
            result.caiDefaultVariety = Object.values(caiDefaultVarieties).find(
                (variety) => variety.asset_id === assetId
            )

        return result
    }, [selectedVariety, caiDefaultVarieties])

    //  QUERY TIMELINES FOR PREPOPULATION
    const {
        data: cai_data,
        isLoading: cai_isLoading,
        isFetching: cai_isFetching,
    } = useVarietyTimelineQuery(caiDefaultVariety?.id)
    const {
        data: acc_data,
        isLoading: acc_isLoading,
        isFetching: acc_isFetching,
    } = useVarietyTimelineQuery(accountDefaultVariety?.id)
    const { data, isLoading, isFetching } = useVarietyTimelineQuery(varietyId)

    // CALCULATE ORIGINAL ROW
    const { originalRow, isPrepopulated = false } = useMemo(() => {
        if (!data || isLoading || isFetching || loadingRisks) return {}
        if (!isValidResponse(data)) return {}

        const varietyTimeline = data.data
        if (!varietyTimeline.default_initial_date) return {}

        const row_id = varietyTimeline.id
        const initial_date = newDateFromYearAgnosticDateString("01-01")

        const originalRow = {
            id: row_id,
            data: { ...varietyTimeline, varietyName },
            stages: buildTimelineStagesFromVarietyStages(
                varietyTimeline.stages,
                initial_date,
                row_id
            ),
        } as ITimelineRow

        if (originalRow.stages.length > 0)
            return {
                originalRow,
                isPrepopulated: false,
            }

        const nextTries = [
            {
                data: acc_data,
                isLoadingOrFetching: acc_isLoading || acc_isFetching,
            },
            {
                data: cai_data,
                isLoadingOrFetching: cai_isLoading || cai_isFetching,
            },
        ]
        let nextTry = nextTries.shift()
        while (nextTry) {
            const _data = nextTry.data
            const _isLoadingOrFetching = nextTry.isLoadingOrFetching

            if (!_data || _isLoadingOrFetching || !isValidResponse(_data)) {
                nextTry = nextTries.shift()
                continue
            }

            const suggestionTimeline = _data.data
            originalRow.stages = buildTimelineStagesFromVarietyStages(
                suggestionTimeline.stages,
                initial_date,
                row_id,
                TIMELINE_EDITION_ACTIONS.ADDED
            )

            originalRow.stages.forEach((stage) => {
                stage.riskProfileStages.forEach((rps, i) => {
                    const suggestedRP = rps.risk_profile
                    for (const accountRP of riskProfiles) {
                        if (isSameRiskProfile(suggestedRP, accountRP)) {
                            stage.riskProfileStages[i] = {
                                ...rps,
                                risk_profile: accountRP,
                                risk_profile_id: accountRP.id,
                            }
                        }
                    }
                })
            })
            if (originalRow.stages.length > 0)
                return {
                    originalRow,
                    isPrepopulated: true,
                }
            else nextTry = nextTries.shift()
        }

        return {
            originalRow,
            isPrepopulated: true,
        }
    }, [data, varietyName, acc_data, cai_data, riskProfiles])

    // ROWS STATE
    const [rows, setRows] = useState<ITimelineRow[]>([])
    useEffect(() => {
        if (!originalRow || Object.keys(originalRow).length === 0) return
        setRows([{ ...originalRow }])
    }, [originalRow])

    // TO SHOW THEM WITH "NEW RISK PROFILE" INDICATOR
    const suggestedRiskProfiles = useMemo(() => {
        if (
            !rows ||
            !rows[0] ||
            Object.keys(rows[0]).length === 0 ||
            loadingRisks
        )
            return []

        const newSuggestedRiskProfiles: IRiskProfile[] = []
        rows.forEach((row) => {
            row.stages.forEach((stage) => {
                stage.riskProfileStages.forEach((rps) => {
                    if (
                        !riskProfilesObj[rps.risk_profile_id] &&
                        rps.risk_profile
                    )
                        newSuggestedRiskProfiles.push(rps.risk_profile)
                })
            })
        })

        return newSuggestedRiskProfiles
    }, [rows, riskProfilesObj, loadingRisks])

    const goOutOfTimeline = () => {
        confirmationModal({
            title: t("areYouSureOutTimeline", "Are you sure you want to exit?"),
            text: t(
                "anyChangesToTheTimelineWillBeLost",
                "Any changes to the timeline will be lost."
            ),
            onContinueLabel: t("goOut"),
            onCancelLabel: t("cancel"),
            onContinue() {
                navigate("../")
            },
        })
    }

    const getRiskProfilesMapAndToCreate = () => {
        const riskProfilesIdsMap: Record<string, string> = {}
        const riskProfilesToCreate: {
            stageId: string
            caiRiskProfileId: string
            riskProfile: IRiskProfile
        }[] = []
        if (!selectedVariety || !selectedAccount)
            return {
                riskProfilesToCreate,
                riskProfilesIdsMap,
            }

        rows.forEach((row) => {
            row.stages.forEach((stage) => {
                stage.riskProfileStages.forEach((rps) => {
                    riskProfilesIdsMap[rps.risk_profile_id] =
                        rps.risk_profile_id
                    const doExist = !!riskProfilesObj[rps.risk_profile_id]
                    if (!doExist) {
                        riskProfilesToCreate.push({
                            stageId: stage.id,
                            caiRiskProfileId: rps.risk_profile_id,
                            riskProfile: {
                                ...rps.risk_profile,
                                account_id: selectedAccount,
                                varieties: [
                                    ...(rps.risk_profile.varieties ?? []),
                                    selectedVariety,
                                ],
                                name: rps.risk_profile.name,
                            },
                        })
                    }
                })
            })
        })
        return {
            riskProfilesToCreate,
            riskProfilesIdsMap,
        }
    }

    const createRiskProfilesIfRequired = async () => {
        const { riskProfilesIdsMap, riskProfilesToCreate } =
            getRiskProfilesMapAndToCreate()

        let riskProfilesCreated = false
        for (const rptc of riskProfilesToCreate) {
            const riskProfileInput = riskProfileToInput(rptc.riskProfile)
            let response = await riskProfileQuerySet.post({
                path: "",
                data: riskProfileInput,
            })
            let isDuplicate = false
            if (!isValidResponse(response)) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                if ((response as any)?.response?.status === 409)
                    isDuplicate = true
                if (!isDuplicate) {
                    enqueueAlert(
                        t(
                            "thereWasAnErrorCreatingRiskProfiles",
                            "There was a problem while creating your risk profiles. Please try again."
                        )
                    )
                    navigate("/admin/assets")
                    return
                }
            }

            response = response as CustomResponse
            if (isDuplicate)
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                response = (response as any)?.response as CustomResponse
            if (!isDuplicate) riskProfilesCreated = true
            riskProfilesIdsMap[rptc.caiRiskProfileId] = response.data[0].id
        }
        if (riskProfilesCreated) queryClient.invalidateQueries("riskProfiles")

        return riskProfilesIdsMap
    }

    const getDeletedStagesIds = () => {
        if (!originalRow || !rows[0]) return []

        const originalStages = arrToDict(originalRow.stages, "id")
        const editedStages = arrToDict(rows[0].stages, "id")
        const deletedStagesIds: string[] = []
        Object.keys(originalStages).forEach((stageId) => {
            if (
                originalStages[stageId].action !==
                    TIMELINE_EDITION_ACTIONS.ADDED &&
                !editedStages[stageId]
            )
                deletedStagesIds.push(stageId)
        })
        return deletedStagesIds
    }

    const getStagesEditions = (riskProfilesIdsMap: Record<string, string>) => {
        const newStages: TimelineStageInput[] = []
        const updatedStages: TimelineStageInput[] = []
        const deletedStagesIds = getDeletedStagesIds()

        if (!selectedVariety)
            return {
                newStages,
                updatedStages,
                deletedStagesIds,
            }

        // set to first of January to keep the stage `days_from_start`
        // relative to the beginning of the year
        const varietyInitialDate = newDateFromYearAgnosticDateString("01-01")

        rows.forEach((row) => {
            row.stages.forEach((stage) => {
                const actualDate = new Date(stage.start_date)

                if (stage.wrappingCount !== undefined)
                    actualDate.setDate(
                        actualDate.getDate() - stage.wrappingCount * 365
                    )

                const newStage: TimelineStageInput = {
                    variety_id: selectedVariety.id,
                    name: stage.name,
                    days_from_start: daysBetween(
                        varietyInitialDate,
                        actualDate
                    ),
                    duration: stage.duration + 1, // For new BE inclusive behavior
                    color: stage.color,
                    risk_profile_ids: stage.riskProfileStages.map((rps) => {
                        return riskProfilesIdsMap[rps.risk_profile_id]
                    }),
                }
                if (stage.action === TIMELINE_EDITION_ACTIONS.ADDED) {
                    newStages.push(newStage)
                } else if (stage.action === TIMELINE_EDITION_ACTIONS.EDITED) {
                    newStage.id = stage.id
                    updatedStages.push(newStage)
                }
            })
        })

        return {
            newStages,
            updatedStages,
            deletedStagesIds,
        }
    }

    const save = async () => {
        // setSaving(true)
        const riskProfilesIdsMap = await createRiskProfilesIfRequired()
        if (!riskProfilesIdsMap) return

        const { newStages, updatedStages, deletedStagesIds } =
            getStagesEditions(riskProfilesIdsMap)

        try {
            for (const stage of newStages) await createStage(stage)
            for (const stage of updatedStages) await updateStage(stage)
            for (const stageId of deletedStagesIds) await deleteStage(stageId)
        } catch (e: unknown) {
            console.error(e)
        } finally {
            queryClient.invalidateQueries(["timeline", varietyId])
            queryClient.invalidateQueries(["varieties"])
            setSaving(false)
            navigate("/admin/assets")
        }
    }

    return (
        <FullScreen>
            <StepsNavigationLayout
                currentStep={0}
                left={
                    <div>
                        <h1 className="title-lg">{varietyName}</h1>
                        <p className="body-md text-gray-60">
                            {t(
                                "timelineInstructions",
                                "Edit timeline and add associated risk profiles to the stages to monitor weather events across the lifetime of your asset."
                            )}
                        </p>
                    </div>
                }
                onCancel={goOutOfTimeline}
                steps={[]}>
                <div className="h-full p-5 flex flex-col gap-3 overflow-hidden">
                    <div className="flex flex-row items-center justify-between">
                        <div className="">
                            <div id="timelineSubtitle"></div>
                        </div>
                        <Button
                            disabled={saving}
                            label={t("saveTimeline", "Save timeline")}
                            onClick={save}
                        />
                    </div>

                    <div className="grow overflow-auto bg-gray-3">
                        <Timeline
                            isPrepopulated={isPrepopulated}
                            suggestedRiskProfiles={suggestedRiskProfiles}
                            timelineSubtitlePortal="timelineSubtitle"
                            isEditing={true}
                            startDate={FIRST_DAY_OF_YEAR_1900}
                            setRows={setRows}
                            rows={rows}
                            headers={
                                <h5 className="w-[140px] grow-0 shrink-0">
                                    {t("riskProfiles", "Risk Profiles")}
                                </h5>
                            }
                            subheaders={
                                <div className="flex flex-row items-center justify-end body-sm text-gray-60 h-full">
                                    Days
                                </div>
                            }
                            renderRowHeaders={() => {
                                return (
                                    <div className="w-[140px] grow-0 shrink-0 pr-2">
                                        <TooltipV2
                                            containerClasses="h-fit grow-0 shrink-0"
                                            position="right"
                                            content={varietyName}>
                                            <h5 className="truncate whitespace-nowrap body-md">
                                                {varietyName}
                                            </h5>
                                        </TooltipV2>
                                    </div>
                                )
                            }}
                            renderRowItem={(
                                row,
                                setRow,
                                riskProfile,
                                accountRiskProfilesObj
                            ) => {
                                const risk = riskProfile
                                const displayName = risk.name ?? riskProfile.id
                                const doExist =
                                    !!accountRiskProfilesObj[risk.id]
                                return (
                                    <TooltipV2
                                        containerClasses="grow h-full"
                                        content={displayName}
                                        position="right">
                                        <div className="flex flex-row items-center h-10 pl-1 group hover:bg-gray-3 transition-all duration-75 body-md">
                                            <div className="flex flex-row items-center gap-2 w-[280px] pr-2">
                                                <div className="w-5 h-5 shrink-0 grow-0 relative">
                                                    <TooltipV2
                                                        position="right"
                                                        contentContainerClass="ml-6 mt-2.5" // not sure why position is not working well without this margins
                                                        content={t(
                                                            "removeRisk",
                                                            "Remove Risk"
                                                        )}>
                                                        {!doExist && (
                                                            <span
                                                                className={[
                                                                    "opacity-100 absolute inset-1 w-3.5 h-3.5",
                                                                    "fill-accent",
                                                                    "transition-all duration-75",
                                                                    "group-hover:opacity-0",
                                                                ].join(" ")}>
                                                                <AsteriskIcon />
                                                            </span>
                                                        )}
                                                        <button
                                                            className={[
                                                                "opacity-0 absolute inset-0 w-5 h-5",
                                                                "fill-gray-60",
                                                                "transition-all duration-75",
                                                                "group-hover:opacity-100",
                                                                "cursor-pointer",
                                                            ].join(" ")}
                                                            onClick={() =>
                                                                confirmationModal(
                                                                    {
                                                                        title: t(
                                                                            "areYouSureDeleteRiskProfileStages",
                                                                            "Are you sure you want to remove this Risk from the timeline?"
                                                                        ),
                                                                        text: "",
                                                                        onContinueLabel:
                                                                            t(
                                                                                "continue",
                                                                                "Continue"
                                                                            ),
                                                                        onCancelLabel:
                                                                            t(
                                                                                "cancel",
                                                                                "Cancel"
                                                                            ),
                                                                        onContinue() {
                                                                            const newRow =
                                                                                {
                                                                                    ...row,
                                                                                }

                                                                            newRow.stages.forEach(
                                                                                (
                                                                                    stage
                                                                                ) => {
                                                                                    const newRiskProfileStages =
                                                                                        stage.riskProfileStages.filter(
                                                                                            (
                                                                                                rps
                                                                                            ) => {
                                                                                                return (
                                                                                                    rps.risk_profile_id !==
                                                                                                    risk.id
                                                                                                )
                                                                                            }
                                                                                        )
                                                                                    if (
                                                                                        stage
                                                                                            .riskProfileStages
                                                                                            .length !==
                                                                                        newRiskProfileStages.length
                                                                                    ) {
                                                                                        stage.riskProfileStages =
                                                                                            newRiskProfileStages
                                                                                        stage.action =
                                                                                            stage.action ??
                                                                                            TIMELINE_EDITION_ACTIONS.EDITED
                                                                                    }
                                                                                }
                                                                            )

                                                                            setRow(
                                                                                newRow
                                                                            )
                                                                        },
                                                                    }
                                                                )
                                                            }>
                                                            <CancelIcon />
                                                        </button>
                                                    </TooltipV2>
                                                </div>
                                                <span
                                                    className={[
                                                        "w-5 h-5",
                                                        "shrink-0 grow-0",
                                                        !doExist
                                                            ? "fill-gray-30"
                                                            : "fill-gray-60",
                                                    ].join(" ")}>
                                                    <RiskIconHandler
                                                        hazardProfiles={
                                                            risk?.[
                                                                "hazard_profiles"
                                                            ] ?? undefined
                                                        }
                                                    />
                                                </span>
                                                <p className="truncate whitespace-nowrap w-full">
                                                    {displayName}
                                                </p>
                                            </div>
                                        </div>
                                    </TooltipV2>
                                )
                            }}
                        />
                    </div>
                </div>
            </StepsNavigationLayout>
        </FullScreen>
    )
}

export default VarietyTimeline
