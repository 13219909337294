const DEFAULT_YEAR = 1900
const DEFAULT_EMPTY_INITIAL_DATE = "00-00"
const DEFAULT_INITIAL_DATE = "01-01"

export function getInitialDate(initialDate: string | undefined) {
    if (!initialDate) return DEFAULT_INITIAL_DATE
    return initialDate // Return the original value, including '00-00'
}

export function getDateFromAgnosticDate(initialDate: string) {
    if (initialDate === DEFAULT_EMPTY_INITIAL_DATE) {
        return undefined
    }
    const [month, day] = initialDate.split("-")
    return new Date(`${DEFAULT_YEAR}-${month}-${day}T12:00:00`)
}

export function isEmptyInitialDate(initialDate: string | undefined) {
    return initialDate === DEFAULT_EMPTY_INITIAL_DATE
}

export function validateVarietyDates(varieties: any[]) {
    return !varieties.some(variety => 
        isEmptyInitialDate(variety.initial_date)
    )
}
