import { useMemo } from "react"
import { useTimeline } from ".."

interface PeriodProps {
    readonly periodNumber: number
    readonly customWidth?: number
    readonly showPeriodNumber?: boolean
    readonly whiteColorPercentage?: number
}

function Period({
    periodNumber,
    customWidth = 5,
    showPeriodNumber = false,
    whiteColorPercentage = 100,
}: PeriodProps) {
    const { pxPerDay } = useTimeline()
    const showLabel = showPeriodNumber ? customWidth > 5 : false

    return (
        <div
            className={
                "flex flex-row items-center justify-center h-full shrink-0 grow-0"
            }
            style={{
                width: pxPerDay * customWidth + "px",
                background:
                    "linear-gradient(90deg, white " +
                    whiteColorPercentage +
                    "%, #f7f8f8 0%)",
            }}>
            {showLabel && (
                <span className="font-roboto tracking-[0.4px] text-[10px] text-gray-60">
                    {periodNumber}
                </span>
            )}
        </div>
    )
}

interface PeriodTimelineProps {
    readonly showPeriodNumber?: boolean
    readonly daysPerPeriod?: number
    readonly totalDays?: number
}

function PeriodTimeline({
    showPeriodNumber = false,
    daysPerPeriod = 5,
    totalDays = 365,
}: PeriodTimelineProps) {
    const periods = useMemo(() => {
        const totalPeriods = Math.ceil(totalDays / daysPerPeriod)
        return Array.from({ length: totalPeriods }, (_, i) => i + 1)
    }, [totalDays, daysPerPeriod])

    return (
        <div
            className={
                "relative h-full flex flex-row items-center divide-x divide-gray-5/90 z-10"
            }>
            {periods.map((periodNumber: number, index: number) => {
                const isLastPeriod = index === periods.length - 1
                const remainingDays = totalDays % daysPerPeriod
                const customWidth =
                    isLastPeriod && remainingDays > 0
                        ? remainingDays
                        : daysPerPeriod

                return (
                    <Period
                        key={"period-" + periodNumber}
                        periodNumber={
                            periodNumber * daysPerPeriod - daysPerPeriod
                        }
                        customWidth={customWidth}
                        showPeriodNumber={showPeriodNumber}
                        whiteColorPercentage={100}
                    />
                )
            })}
        </div>
    )
}

export default PeriodTimeline
