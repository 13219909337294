import { useEffect, useRef, useState } from "react"
import { useTranslate } from "@tolgee/react"
import { Button } from "../../../../climateui/components"
import { GenericPageHeader } from "../../../../components"
import { ResponsivePaddingWrapper } from "../../../../layouts/TabLayout"
import { usePlanningTool } from "../../../../providers/PlanningToolProvider"
import { useAuth } from "../../../../providers/AuthProvider"
import { usePlan } from "../PlanProvider"
import { useAccount } from "../../../../providers/AccountProvider"
import queryClient, { planPOST, planPUT } from "../../../../utils/networking"
import _ from "lodash"

import {
    areResTypeAndDataValid,
    CustomResponse,
} from "../../../../climateui/utils/http"
import { useLocation, useNavigate } from "react-router-dom"
import { cleanPlanPayload, createEditedPlanObject } from "../utils"
import EmptyModal from "../../../../climateui/providers/Modal/EmptyModal"
import Lottie from "lottie-react"
import climateAILogoIconAnimation from "../../../../components/ClimateAi_Symbol_Animation.json"
import { useMutation } from "react-query"
import SeasonalCalendarProvider from "../../../../providers/SeasonalCalendarProvider"
import SeasonalCalendar from "../../../../components/SeasonalCalendar/SeasonalCalendar"
import { useToast } from "../../../../climateui/providers/Toast/ToastContextProvider"
import { GenericInput } from "../../../../climateui/components/Inputs"
import { TODAY } from "../../../../utils/dates"

function PlanStagesAndRisks() {
    const { goToStep, activePlanId } = usePlanningTool()

    const auth = useAuth()
    const { selectedAccount } = useAccount()
    const { enqueueAlert } = useToast()
    const {
        modifiableStrategiesObj,
        setModifiableStrategiesObj,
        workingPlan,
        setWorkingPlan,
        deletedStagesOrRisks,
        originalStrategiesObj,
        isEditingPlan,
        deleteStrategy,
        duplicateStrategy,
        deleteStageOrRisk,
    } = usePlan()
    const navigate = useNavigate()
    const location = useLocation()

    const [storingPlan, setStoringPlan] = useState(false)
    const [addingName, setAddingName] = useState(false)

    const [loadingPlanCreation, setLoadingPlanCreation] = useState(false)

    const { t } = useTranslate()

    const { mutate: createPlan } = useMutation(planPOST, {
        onSuccess: (res) => {
            if (!areResTypeAndDataValid(res)) {
                setStoringPlan(false)
                enqueueAlert(
                    t(
                        "thereWasAnErrorCreatingThePlan",
                        "There was an error creating the plan"
                    )
                )
                return
            }
            const data = (res as CustomResponse).data || []
            if (data.id) {
                queryClient.invalidateQueries(["plans", selectedAccount])
                setStoringPlan(false)
                navigate("/seasonal/plans/" + data.id)
                enqueueAlert(
                    t("PLANCreatedSuccessfully", "Plan created successfully!", {
                        plan: workingPlan?.name || t("thePlan", "The plan"),
                    })
                )
            }
        },
        onError: (error) => {
            setStoringPlan(false)
            enqueueAlert(
                t(
                    "thereWasAnErrorCreatingThePlan",
                    "There was an error creating the plan"
                )
            )
            console.error(error)
        },
    })

    const { mutate: updatePlan } = useMutation(planPUT, {
        onSuccess: (res) => {
            if (!areResTypeAndDataValid(res)) {
                setStoringPlan(false)
                enqueueAlert(
                    t(
                        "thereWasAnErrorUpdatingThePlanInfo",
                        "There was an error updating the plan information"
                    )
                )
                return
            }
            const data = (res as CustomResponse).data || []
            if (data.id) {
                queryClient.invalidateQueries(["plans", selectedAccount])
                queryClient.invalidateQueries(["plan", activePlanId])
                setStoringPlan(false)
                navigate("/seasonal/plans/" + data.id)
                enqueueAlert(
                    t("PLANEditedSuccessfully", "Plan edited successfully!", {
                        plan: workingPlan?.name || t("thePlan", "The plan"),
                    })
                )
            }
        },
        onError: (error) => {
            setStoringPlan(false)
            enqueueAlert(
                t(
                    "thereWasAnErrorUpdatingThePlanInfo",
                    "There was an error updating the plan information"
                )
            )
            console.error(error)
        },
    })

    const save = () => {
        setLoadingPlanCreation(true)
        if (activePlanId) {
            updatePlan(
                createEditedPlanObject(
                    {
                        ...workingPlan,
                        updated_by: auth.user?.email || "empty@mail.com",
                        account_id: selectedAccount,
                    },
                    _.cloneDeep(originalStrategiesObj),
                    _.cloneDeep(modifiableStrategiesObj),
                    [...deletedStagesOrRisks]
                )
            )
        } else {
            createPlan(
                cleanPlanPayload({
                    ...workingPlan,
                    created_by: auth.user?.email || "empty@mail.com",
                    account_id: selectedAccount,
                    strategies: Object.values(
                        _.cloneDeep(modifiableStrategiesObj)
                    ),
                })
            )
        }
    }

    const nameInputRef = useRef<HTMLInputElement>(null)

    const next = () => {
        setStoringPlan(true)

        if (workingPlan && !workingPlan.name) {
            setStoringPlan(false)
            setAddingName(true)
            return
        }

        save()
    }

    useEffect(() => {
        if (addingName) {
            const timeout = setTimeout(() => {
                nameInputRef.current?.focus()
            }, 100)

            return () => clearTimeout(timeout)
        }
    }, [addingName])

    const prev = () => {
        goToStep("assets")
    }

    useEffect(() => {
        // Don't navigate back while we are in Edit mode and waiting for workingPlan data to load
        if (
            !location.pathname.includes("new") &&
            (!workingPlan || _.isEmpty(workingPlan))
        )
            return

        // If no data, go back to locations
        const selectedLocationsAssetVarieties =
            workingPlan?.selectedLocationsAssetVarieties
        if (
            !selectedLocationsAssetVarieties ||
            _.isEmpty(selectedLocationsAssetVarieties)
        ) {
            goToStep("locations")
        }
    }, [workingPlan?.selectedLocationsAssetVarieties])

    return (
        <ResponsivePaddingWrapper extraClasses="p-3 px-4 sm:p-3 sm:px-5 elevation-1 border-r border-gray-5">
            <div className="flex flex-col h-full overflow-hidden grow gap-1">
                <GenericPageHeader
                    pageTitle={t(
                        "planStagesAndRisksInstructions",
                        "Map out planning scenarios for your assets and locations."
                    )}
                    pageSubtitle={t(
                        "planStagesAndRisksDetailedInstructions",
                        "After editing, click Run Plan to calculate expected risks for the next 6 months. Edits made to plans will not impact the configuration of your alerts or assets."
                    )}
                    extraClasses="[&_h2]:title-sm [&_h2]:mb-2"
                    right={
                        <div className="flex flex-row items-center gap-2">
                            <Button
                                type="secondary"
                                label={t("back")}
                                onClick={prev}
                            />

                            <Button
                                label={t("runPlan")}
                                onClick={next}
                            />
                        </div>
                    }
                    bottom={undefined}
                />
                <div className="overflow-auto bg-white grow">
                    <SeasonalCalendarProvider
                        startDate={TODAY}
                        deleteStrategy={deleteStrategy}
                        duplicateStrategy={duplicateStrategy}
                        deleteStageOrRisk={deleteStageOrRisk}
                        editMode={isEditingPlan}
                        modifiableStrategies={modifiableStrategiesObj}
                        setModifiableStrategies={setModifiableStrategiesObj}>
                        <SeasonalCalendar />
                    </SeasonalCalendarProvider>
                </div>
                <EmptyModal
                    open={storingPlan}
                    customClasses="h-fit w-[320px] relative rounded-lg bg-white p-5">
                    <div className="w-16">
                        {/* TODO: implement whitelabel logic. Ask Marco. */}
                        <Lottie animationData={climateAILogoIconAnimation} />
                    </div>
                    <h3 className="title-sm text-gray-90 mt-2">
                        {t("runningCalculations", "Running calculations...")}
                    </h3>
                    <p className="body-md text-gray-60 mt-3">
                        {t(
                            "calculatingForecastAndImpactForPlan",
                            "We are calculating the risk forecasts for this plan. Please wait, this can take a little while."
                        )}
                    </p>
                </EmptyModal>
                <EmptyModal
                    open={addingName}
                    customClasses="h-fit w-[390px] relative rounded-lg bg-white p-5">
                    <h3 className="title-lg font-medium text-gray-90">
                        {t("pleaseNameYourPlan", "Please name your plan")}
                    </h3>
                    <div className="mt-3">
                        <GenericInput
                            inputRef={nameInputRef}
                            handleChange={(event) => {
                                setWorkingPlan({
                                    ...workingPlan,
                                    name: event.target.value,
                                })
                            }}
                            placeholder={t("enterName", "Enter name")}
                            type="text"
                        />
                    </div>
                    <div className="flex flex-row items-center justify-end gap-2 mt-6">
                        <Button
                            onClick={() => setAddingName(false)}
                            label={t("back", "Back")}
                            type="secondary"></Button>
                        <Button
                            onClick={save}
                            label={t("continue", "Continue")}
                            disabled={!workingPlan?.name || loadingPlanCreation}
                        />
                    </div>
                </EmptyModal>
            </div>
        </ResponsivePaddingWrapper>
    )
}

export default PlanStagesAndRisks
