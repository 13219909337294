import { useEffect, useMemo, useRef, useState } from "react"
import { Link } from "react-router-dom"
import CalendarHeader from "./CalendarHeader"
import { DateTime, Interval } from "luxon"
import WidgetStates from "../SeasonalWidgets/WidgetStates"
import {
    CalendarTimelineItem,
    ICalendarTimelineRow,
} from "../../../types/widget"
import { ITableWidgetProps } from "./TableWidget"
import PaginationControls from "../../Table/PaginationControls"
import { getGranularityArray } from "../../../utils/calendar"
import usePaginationControls from "../../../hooks/usePaginationControls"
import {
    getTimeWindowByGranularity,
    TimeResolutionValues,
} from "../../../../views/Seasonal/Alerts/utils"
import AlertItem from "./AlertItem"

const GenericCalendarWidget = ({
    granularity,
    alertsCalendarData,
    toolTip,
    widgetProps,
}: {
    readonly granularity: string
    readonly alertsCalendarData: ICalendarTimelineRow[]
    readonly toolTip: any
    readonly widgetProps: ITableWidgetProps
}) => {
    const paginationControls = usePaginationControls(alertsCalendarData)
    const [width, setWidth] = useState(0)
    const calendarRef = useRef(null)
    const [startDate, endDate] = useMemo(
        () => getTimeWindowByGranularity(granularity as TimeResolutionValues),
        [granularity]
    )
    const betweenDates = Interval.fromDateTimes(startDate, endDate)

    // Calculate granularity dimensions based on interval and granularity type
    const { steps, granularityWidth } = useMemo(() => {
        let steps = 0
        let granularityWidth = 0
        if (["daily", "monthly"].includes(granularity)) {
            steps = betweenDates.length("days") + 1
            granularityWidth = width / steps
        } else if (granularity === "weekly") {
            steps = betweenDates.length("weeks") + 1
            granularityWidth = width / 32
        }
        return { steps, granularityWidth }
    }, [granularity, width, betweenDates])

    const shownData = useMemo(() => {
        if (!alertsCalendarData) return []
        return widgetProps.isPaginated
            ? alertsCalendarData.slice(
                  paginationControls.startingIndex,
                  paginationControls.endingIndex
              )
            : alertsCalendarData
    }, [
        alertsCalendarData,
        paginationControls.startingIndex,
        paginationControls.endingIndex,
        widgetProps.isPaginated,
    ])

    const calendarData = useMemo(() => {
        if (!shownData) return []
        return shownData.map((locRow: ICalendarTimelineRow) => {
            let overlap = true
            const calendarRow: CalendarTimelineItem[][] = [[locRow.items[0]]]
            locRow.items.slice(1).forEach((item) => {
                for (const alertRow of calendarRow) {
                    if (
                        item.startDate > alertRow[alertRow.length - 1].endDate
                    ) {
                        alertRow.push(item)
                        overlap = false
                        break
                    }
                }
                overlap && calendarRow.push([item])
            })
            return {
                text: locRow.text,
                items: calendarRow,
                count: locRow.items.length,
            }
        })
    }, [shownData])

    const getEachDateSpaceLength = (granularityArray: DateTime[]) => {
        const eachDateSpaceLength: number[] = []
        granularityArray.forEach((date: DateTime, i) => {
            if (i !== granularityArray.length - 1) {
                const dateInterval = Interval.fromDateTimes(
                    granularityArray[i],
                    granularityArray[i + 1]
                ).length("days")
                eachDateSpaceLength.push(dateInterval)
            } else {
                const lastDateSpace =
                    Interval.fromDateTimes(granularityArray[i], endDate).length(
                        "days"
                    ) + 1
                eachDateSpaceLength.push(lastDateSpace)
            }
        })
        return { eachDateSpaceLength }
    }

    const { granularityArray, shownDates } = getGranularityArray(
        startDate,
        granularity,
        steps,
        steps,
        1
    )
    const { eachDateSpaceLength } = getEachDateSpaceLength(granularityArray)

    useEffect(() => {
        const handleResize = () => {
            setWidth(
                calendarRef.current ? calendarRef.current["offsetWidth"] : 0
            )
        }
        handleResize()
        window.addEventListener("resize", handleResize)
        window.addEventListener("force-resize", handleResize)

        return () => {
            window.removeEventListener("resize", handleResize)
            window.removeEventListener("force-resize", handleResize)
        }
    }, [])

    return (
        <div className="w-full h-full overflow-visible border border-1 border-gray-14 rounded-lg relative z-1 p-2 bg-white">
            <h1
                className={`w-full flex h-[48px] p-0 pl-2 items-center top-0 left-0 z-10 label-lg ${
                    widgetProps.loading ? "text-gray-30" : "text-gray-90"
                }`}>
                {widgetProps.title}
            </h1>
            <div className="flex justify-end border-b border-gray-14 items-center w-full">
                <div
                    ref={calendarRef}
                    className="flex-wrap w-[90%]">
                    <div className="flex w-full">
                        <CalendarHeader
                            shownDates={shownDates}
                            responsiveWidth={granularityWidth}
                            eachSpaceLength={eachDateSpaceLength}
                        />
                    </div>
                </div>
            </div>

            <WidgetStates
                data={widgetProps.data}
                loading={widgetProps.loading}
                reload={widgetProps.reload}
                error={widgetProps.error}
                columns={[]}
                errorMessage={widgetProps.errorMessage}
                reloadMessage={widgetProps.reloadMessage}
                noResultsMessage={widgetProps.noResultsMessage}
                tryAnotherFilterMessage={widgetProps.tryAnotherFilterMessage}
            />

            {calendarData.map((location) => (
                <div
                    key={location.text.value}
                    className="flex w-full items-stretch border-b-[0.5px] border-gray-14 h-full bg-gray-3">
                    <div className="w-[10%] flex-wrap pl-2">
                        <Link
                            to={location.text.link ?? ""}
                            className="text-[14px] cursor-pointer underline text-accent w-full">
                            {location.text.value}
                        </Link>
                        <h1 className="body-sm w-full text-gray-60">
                            {location.count +
                                (location.count > 1 ? " alerts" : " alert")}
                        </h1>
                    </div>

                    <div
                        className="flex flex-col w-[90%] -ml-[1px] py-2 overflow-x-visible"
                        style={{
                            background: `repeating-linear-gradient(to right, #F2F3F3, #F2F3F3 1px, white 0px, white ${
                                granularity !== "monthly"
                                    ? granularityWidth
                                    : granularityWidth * 7
                            }px)`,
                        }}>
                        {location.items.map((alertsRow) => (
                            <div
                                key={`alertRow-${alertsRow[0].alertHoverInfo.riskSettingId}`}
                                className="flex w-full relative h-10">
                                {alertsRow.map((item, index) => (
                                    <AlertItem
                                        key={"AlertItem" + index}
                                        item={item}
                                        granularityWidth={granularityWidth}
                                        startDate={startDate}
                                        endDate={endDate}
                                        toolTip={toolTip}
                                        width={width}
                                    />
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
            ))}

            {widgetProps.isPaginated && (
                <PaginationControls
                    selectedPageSize={paginationControls.per_page}
                    paginationOptions={paginationControls.defaultPageSizeOpts}
                    handlePageSizeChange={paginationControls.onPageSizeChange}
                    handleFirstClick={paginationControls.onFirstClick}
                    handleLastClick={paginationControls.onLastClick}
                    handlePrevClick={() =>
                        paginationControls.onPageChange(false)
                    }
                    handleNextClick={() =>
                        paginationControls.onPageChange(true)
                    }
                    backwardDisabled={paginationControls.page === 1}
                    forwardDisabled={
                        paginationControls.page ===
                        paginationControls.totalPages
                    }
                    currentPage={paginationControls.page}
                    totalPages={paginationControls.totalPages}
                    outOfText={"of"}
                />
            )}
        </div>
    )
}

export default GenericCalendarWidget
