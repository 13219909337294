import React, { useMemo, useState } from "react"
import {
  Bar,
  Cell,
  ComposedChart,
  Label,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"
import { AxisInterval } from "recharts/types/util/types"
import LoadingAnimation from "../../../../climateui/components/LoadingAnimation"
import ProbabilityHover from "../../../../climateui/components/Widgets/SeasonalWidgets/ProbabilityHover"
import RiskOutlookIcon from "../../../../climateui/icons/variableIcons/RiskOutlookIcon"
import { getProbabilityHoverItems } from "../riskOutlookUtils"
import renderCustomLegend, { LegendInterface } from "./renderCustomLegend"
import renderRiskChartReferences from "./renderRiskChartReferences"
import RiskOutlookChartHeader from "./RiskOutlookChartHeader"

interface Point {
  // id: string
  label: string
  value: number
  color?: string
  y?: number
  y0?: number
  y1?: number
  yMin?: number
  yMid?: number
  yMax?: number
}

export interface Plot {
  id: string
  visualization: "bar"
  // color: string
  points: Point[]
}

export interface RiskOutlookBarChartProps {
  data: {
    plot: Plot[]
  }
  domain?: [number, number]
  xTickFormatter?: (value: string) => string
  xAxisInterval?: AxisInterval | number
  loading?: boolean
  legends?: LegendInterface[]
  xLabel?: string
  yLabel?: string
  description?: string
  tooltip?: string
  title?: string
  icon?: string
  lowerBound?: number
  upperBound?: number
}

const DEFAULT_RISK_BAR_CHART_DATA_KEY = "risk-outlook-past-events"

interface TooltipProps {
  active?: boolean
  payload?: any[]
  dataKey: string
  lowerBound: number
  upperBound: number
}

const RiskOutlookTooltip: React.FC<TooltipProps> = ({
  active,
  payload,
  dataKey,
  lowerBound,
  upperBound,
}) => {
  if (!active || !payload?.length) return null

  // get currently selected data set (forecast or past events)
  let currentGrid = payload.find((obj) => obj.dataKey === dataKey)
  // default and aim to position 1 (forecast)
  if (!currentGrid) {
    const value0 = parseFloat(payload?.[0]?.value?.toString() ?? "0")
    const value1 = parseFloat(payload?.[1]?.value?.toString() ?? "0")
    if (value0 > 0) currentGrid = payload[0]
    if (value1 > 0) currentGrid = payload[1]
  }

  if (!currentGrid) return null

  const value = parseFloat(currentGrid?.value?.toString() ?? "0").toFixed(2)

  if (currentGrid.dataKey === "risk-score-forecast") {
    const { y0, yMin, yMid, yMax }: Point = currentGrid?.payload?.meta ?? {}
    return (
      <div className="bg-white p-2.5 rounded shadow border border-gray-10">
        <ProbabilityHover
          title="Probability Breakdown by Severity"
          leftHeader="Severity"
          rightHeader={(y0 ?? 0).toFixed(2).toString()}
          items={getProbabilityHoverItems(
            yMin ?? 0,
            yMid ?? 0,
            yMax ?? 0,
            lowerBound ?? 0,
            upperBound ?? 0,
          )}
        />
      </div>
    )
  } else if (currentGrid.dataKey === "risk-score-past-events") {
    return (
      <div className="bg-white p-2.5 rounded shadow border border-gray-10">
        <ProbabilityHover
          title="Past events index"
          items={[
            {
              title: "Severity",
              description: "Historical",
              value: value,
              icon: () => <RiskOutlookIcon />,
            },
          ]}
        />
      </div>
    )
  }
  return null
}

const RiskOutlookBarChart: React.FC<RiskOutlookBarChartProps> = ({
  data,
  domain = [0, 100],
  xTickFormatter,
  xAxisInterval,
  loading,
  legends,
  xLabel,
  yLabel,
  description,
  tooltip,
  title,
  icon,
  lowerBound = 70,
  upperBound = 90,
}) => {
  const [dataKey, setDataKey] = useState(DEFAULT_RISK_BAR_CHART_DATA_KEY)
  const transformedData = useMemo(() => {
    // Create a map to store combined values for each label
    const combinedData = new Map()

    // Process each plot's points
    data?.plot?.forEach((plot) => {
      plot.points.forEach((point) => {
        if (!combinedData.has(point.label)) {
          combinedData.set(point.label, {
            name: point.label,
            meta: { ...point },
          })
        }
        // Add this plot's value and color to the combined data
        const entry = combinedData.get(point.label)
        entry[plot.id] = point.value
        entry[`${plot.id}-color`] = point.color
      })
    })

    // Convert map to array
    return Array.from(combinedData.values())
  }, [data.plot])

  if (loading) {
    return (
      <div className="flex justify-center items-center mt-36 body-lg">
        <LoadingAnimation />
      </div>
    )
  }

  if (transformedData.length === 0) {
    return <div>No data points available for the chart.</div>
  }

  return (
    <>
      <RiskOutlookChartHeader
        title={title}
        tooltip={tooltip}
        icon={icon}
        description={description}
      />
      <div>
        <ResponsiveContainer
          width="100%"
          height={350}>
          <ComposedChart
            data={transformedData}
            margin={{ left: 60, bottom: 20, right: 50 }}>
            <Legend
              verticalAlign="top"
              align="right"
              layout="horizontal"
              content={() => renderCustomLegend({ legends })}
            />
            <XAxis
              axisLine={{ stroke: "#dfdfdf" }}
              tickLine={false}
              dataKey="name"
              type="category"
              allowDuplicatedCategory={false}
              strokeWidth={0}
              interval={xAxisInterval ?? 0}
              fontSize={12}
              tickFormatter={xTickFormatter}>
              {xLabel && (
                <Label
                  value={xLabel}
                  position="insideBottom"
                />
              )}
            </XAxis>

            <YAxis
              padding={{ top: 10 }}
              domain={domain}
              axisLine={false}
              tickLine={false}
              tick={false}
              strokeWidth={0}
            />

            <ReferenceLine
              y={0}
              stroke={"#aaa"}
            />

            {renderRiskChartReferences({
              lowerBound,
              upperBound,
            })}

            <Bar
              dataKey="risk-score-past-events"
              stackId={"1"}
              barSize={35}
              data={transformedData}>
              {transformedData.map((entry) => (
                <Cell
                  key={`cell-past-events-${entry.label}`}
                  fill={entry["risk-score-past-events-color"] || "#004957"}
                  // Wrong typings for this prop in the recharts library
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  radius={
                    entry["risk-score-forecast"] === 0
                      ? [4, 4, 0, 0]
                      : [0, 0, 0, 0]
                  }
                  onMouseEnter={() => {
                    if (dataKey !== "risk-score-past-events")
                      setDataKey("risk-score-past-events")
                  }}
                  onMouseLeave={() => {
                    if (dataKey === "risk-score-past-events")
                      setDataKey(DEFAULT_RISK_BAR_CHART_DATA_KEY)
                  }}
                />
              ))}
            </Bar>

            <Bar
              dataKey="risk-score-forecast"
              stackId={"1"}
              barSize={35}
              data={transformedData}>
              {transformedData.map((entry) => (
                <Cell
                  key={`cell-forecast-${entry.label}`}
                  fill={entry["risk-score-forecast-color"] || "#BF1728"}
                  // Wrong typings for this prop in the recharts library
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  radius={[4, 4, 0, 0]}
                  onMouseEnter={() => {
                    if (dataKey !== "risk-score-forecas")
                      setDataKey("risk-score-forecast")
                  }}
                  onMouseLeave={() => {
                    if (dataKey === "risk-score-forecast")
                      setDataKey(DEFAULT_RISK_BAR_CHART_DATA_KEY)
                  }}
                />
              ))}
            </Bar>

            <Tooltip
              allowEscapeViewBox={{ y: true }}
              isAnimationActive={false}
              wrapperStyle={{
                zIndex: 1000,
              }}
              formatter={(value, name) => {
                if (typeof value === "number") {
                  return [value.toFixed(2), name]
                }
                return [value, name]
              }}
              content={({ active, payload }) => (
                <RiskOutlookTooltip
                  active={active}
                  payload={payload}
                  dataKey={dataKey}
                  lowerBound={lowerBound}
                  upperBound={upperBound}
                />
              )}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </>
  )
}

export default RiskOutlookBarChart
