/* Number Input Component: Candidate for ClimateUI
 * -----------------------------------------------
 * Specialized input component for numerical user input
 */

import { ArrowBottom } from "../climateui/icons"
import { useEffect, useMemo, useState, KeyboardEvent } from "react"
import { Tooltip } from "../climateui/components"
import { IBooleanDictionary } from "../climateui/types"

const arrowClasses =
    "w-[18px] h-[18px] fill-gray-60 group-hover:fill-gray-90 shrink-0 pointer-events-none"
const NO_ERRORS = {
    beyondUpperBound: false,
    beyondLowerBound: false,
    NaN: false,
}
interface IErrorMessages {
    beyondUpperBound: string
    beyondLowerBound: string
    NaN: string
}
const DEFAULT_ERROR_MESSAGES: IErrorMessages = {
    beyondUpperBound: "Exceeds the allowed maximum ",
    beyondLowerBound: "Below the required minimum",
    NaN: "Not a valid number",
}

const NumberInput = ({
    initialValue,
    onChange,
    min, // inclusive
    max, // inclusive
    isPercentage = false,
    errorMessages = DEFAULT_ERROR_MESSAGES,
    isInteger,
    suffix,
    extraClasses,
    disabled = false,
}: {
    onChange: (value: number | undefined) => void
    initialValue?: number
    min?: number
    max?: number
    isPercentage?: boolean
    errorMessages?: IErrorMessages
    isInteger?: boolean
    suffix?: string
    extraClasses?: string
    disabled?: boolean
}) => {
    const formatter = Intl.NumberFormat(undefined, { maximumFractionDigits: 2 })

    let iv = ""
    if (initialValue) {
        iv = isPercentage
            ? (initialValue * 100).toString()
            : initialValue.toString()
    }
    const [rawValue, setRawValue] = useState<string>(iv)
    const [value, _setValue] = useState<number | undefined>()
    const [localChange, setLocalChange] = useState(false)
    const setValue = (_val?: number) => {
        let val = _val
        if (isInteger && val !== undefined) {
            val = parseInt(val.toFixed(0))
        }
        setLocalChange(true)
        _setValue(val)
    }
    const [errors, setErrors] = useState(NO_ERRORS)
    const hasErrors = useMemo(
        () => Object.values(errors).some((val) => val === true),
        [errors]
    )
    useEffect(() => {
        if (initialValue === undefined) return
        setErrors(NO_ERRORS)
        const initVal = isPercentage ? initialValue * 100 : initialValue
        setRawValue(formatter.format(initVal))
    }, [initialValue])

    useEffect(() => {
        setErrors(NO_ERRORS)
        if (rawValue === undefined || rawValue.length === 0) {
            return setValue()
        }
        // If it contains anything other than numbers, set value to undefined
        if (!/^-?\d+\.?\d*$/.test(rawValue)) {
            setErrors({ ...NO_ERRORS, NaN: true })
            return setValue()
        }
        let parsedValue = parseFloat(rawValue)
        // If it is not a valid number, set value to undefined
        if (isNaN(parsedValue)) {
            setErrors({ ...NO_ERRORS, NaN: true })
            return setValue()
        }
        // Convert to percentage if needed
        parsedValue = isPercentage ? parsedValue / 100 : parsedValue

        // If it doesn't fall between the specified ranges, undefined
        if (max !== undefined && parsedValue > max) {
            setErrors({
                ...errors,
                beyondUpperBound: true,
                beyondLowerBound: false,
            })
        } else if (min !== undefined && parsedValue < min) {
            setErrors({
                ...errors,
                beyondLowerBound: true,
                beyondUpperBound: false,
            })
        }
        setValue(parsedValue)
    }, [rawValue, min, max])

    // Report changes
    useEffect(() => {
        if (hasErrors) return onChange(undefined)
        if (localChange) {
            setLocalChange(false)
            return onChange(value)
        }
    }, [hasErrors, value, localChange])

    const formattedErrorMessages = useMemo(() => {
        const errorKeys = Object.keys(errors).filter(
            (k) => (errors as IBooleanDictionary)[k]
        )
        if (errorKeys.length === 0) return ""
        return errorKeys
            .map((k) => (errorMessages as unknown as Record<string, string>)[k])
            .join("; ")
    }, [errors])

    const applyStep = (step: number) => {
        if (value === undefined) {
            return
        }
        const val = isPercentage ? value * 100 : value
        setRawValue(formatter.format(val + step))
    }
    const avoidSuffix = (event: KeyboardEvent<HTMLInputElement>) => {
        if (!isPercentage && !suffix) return
        const target = event.target as HTMLInputElement
        const caretPosition = target.selectionStart || 0
        if (caretPosition > rawValue.length) {
            target.setSelectionRange(rawValue.length, rawValue.length)
        }
    }
    const getWidth = () => {
        if (isPercentage || suffix) return "w-20"
        if (isInteger) return "w-14"
        return "w-16"
    }
    const getPlaceholder = () => {
        if (isPercentage) return "%"
        if (suffix) return suffix
        return ""
    }
    const getValue = () => {
        if (isPercentage && rawValue) return rawValue + "%"
        if (suffix && rawValue) return rawValue + suffix
        return rawValue
    }
    return (
        <div
            className={`relative w-fit h-full ${
                disabled
                    ? "pointer-events-none cursor-not-allowed opacity-50"
                    : ""
            }`}
            aria-disabled={disabled ? "true" : "false"}>
            <Tooltip
                customStyle="h-full"
                doShow={hasErrors}
                content={formattedErrorMessages}>
                <input
                    type="text"
                    className={[
                        getWidth(),
                        "pl-2 pr-5 py-[0.322em]",
                        "border border-gray-14 rounded-sm hover:border-gray-20 outline-none",
                        extraClasses,
                        hasErrors ? "bg-red-light border-red text-red" : "",
                        "focus:border-accent",
                        "h-full",
                    ].join(" ")}
                    placeholder={getPlaceholder()}
                    value={getValue()}
                    onChange={(event) => {
                        let val = event.target.value
                        if (isPercentage) {
                            val = event.target.value.replace("%", "")
                        } else if (suffix) {
                            val = event.target.value.replace(suffix, "")
                        }
                        setRawValue(val)
                    }}
                    onKeyDown={(event) => {
                        if (event.key === "ArrowUp") return applyStep(1)
                        if (event.key === "ArrowDown") return applyStep(-1)
                    }}
                    onKeyUp={(event) => {
                        avoidSuffix(event)
                    }}
                    disabled={disabled}
                />
            </Tooltip>
            <div className="absolute right-1.5 inset-y-0 flex flex-col gap-0.5 justify-center">
                <div
                    role="button"
                    className="flex flex-row items-center justify-center w-2 h-2 cursor-pointer group"
                    onClick={() => applyStep(1)}>
                    <span className={arrowClasses + " rotate-180"}>
                        <ArrowBottom />
                    </span>
                </div>
                <div
                    role="button"
                    className="flex flex-row items-center justify-center w-2 h-2 cursor-pointer group"
                    onClick={() => applyStep(-1)}>
                    <span className={arrowClasses}>
                        <ArrowBottom />
                    </span>
                </div>
            </div>
        </div>
    )
}
export default NumberInput
