import {
    RefObject,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react"
import { IInsightsLocation, ISeasonalStat } from "../../../../types"
import CustomMapControls from "../../../../climateui/components/Map/CustomMapControls"
import {
    getDirectionOutlook,
    getIsMeanInMass,
    getLargestTercile,
} from "../../../../utils/transform"
import DirectionalPinsMapLegend from "./DirectionalPinsMapLegend"
import { useCustomFlags } from "../../../../hooks"
import mapboxgl from "../../../../climateui/components/Map/mapboxgl"
import { setupMap } from "../../../../climateui/components/Map/ShadedRegionsMap/utils"
import { pinElements } from "./pins"

/* INTERFACES & TYPES > START */
type DirectionEntry = [string, [number, number]]
/* INTERFACES & TYPES < END */

/* CONSTS > START */
const DEFAULT_MAPBOX_CONFIG = {
    style: "mapbox://styles/geramv/cl5k2k0ms001414ni9wxhbpsd",
    center: [0, 0] as [number, number],
    zoom: 1,
    minZoom: 0,
    maxZoom: 11,
    attributionControl: false,
}
/* CONSTS < END */

/* UTILITY > START */
const clearMap = (
    mapboxMap: mapboxgl.Map | undefined,
    markers: RefObject<mapboxgl.Marker[]>
) => {
    // Clear all previous markers
    markers.current?.forEach((marker) => marker.remove())
    // Reset position
    mapboxMap?.flyTo({
        center: DEFAULT_MAPBOX_CONFIG.center,
        zoom: DEFAULT_MAPBOX_CONFIG.zoom,
    })
}
const paintPins = ({
    locations,
    mapboxMap,
    pinElement,
}: {
    locations: DirectionEntry[]
    mapboxMap: mapboxgl.Map
    pinElement: HTMLDivElement
}) => {
    if (!pinElement) return []
    return locations.map(([locationName, coords]) => {
        const clonePin = pinElement.cloneNode(true)
        const popup = new mapboxgl.Popup({
            closeButton: false,
        }).setText(locationName)

        const marker = new mapboxgl.Marker(clonePin)
            .setPopup(popup)
            .setLngLat(coords)
            .addTo(mapboxMap)

        clonePin.addEventListener("mouseenter", () => marker.togglePopup())
        clonePin.addEventListener("mouseleave", () => marker.togglePopup())
        return marker
    })
}
/* UTILITY < END */

const DirectionalPinsMap = ({
    title,
    loading,
    data,
    toggleNewDirectonalityLogic = false,
}: {
    title?: string
    loading?: boolean
    data: {
        location: IInsightsLocation
        stats: { results: ISeasonalStat[] }
    }[]
    toggleNewDirectonalityLogic?: boolean
}) => {
    /* DEFAULTS > START */
    const {
        ui_yield_outlook_directional_pin_map_min_zoom: flagsmith_minZoom,
        ui_yield_outlook_directional_pin_map_max_zoom: flagsmith_maxZoom,
    } = useCustomFlags([
        "ui_yield_outlook_directional_pin_map_min_zoom",
        "ui_yield_outlook_directional_pin_map_max_zoom",
    ])
    const minZoom = flagsmith_minZoom.value as number | undefined
    const maxZoom = flagsmith_maxZoom.value as number | undefined
    /* DEFAULTS < END */

    /* STATE > START */
    const [mapboxMap, setMapboxMap] = useState<mapboxgl.Map>()
    const markers = useRef<mapboxgl.Marker[]>([])
    const prevContainerRect = useRef<DOMRect | undefined>()
    const containerRef = useRef<HTMLElement | undefined>()

    const mapRef = useCallback((container: HTMLDivElement | null) => {
        if (!container) return
        containerRef.current = container

        const mapboxConfig = { ...DEFAULT_MAPBOX_CONFIG, container }
        if (minZoom) mapboxConfig.minZoom = minZoom
        if (maxZoom) mapboxConfig.maxZoom = maxZoom

        const mapboxMap = setupMap({
            containerRef,
            mapboxConfig,
            prevContainerRect,
        })
        setMapboxMap(mapboxMap)
    }, [])

    const categorizedCoordinates = useMemo(() => {
        // Clear map on data change
        clearMap(mapboxMap, markers)

        const _categorizedCoordinates: DirectionEntry[][] = [
            [], // below, -1
            [], // within, 0
            [], // above, 1,
            [], // no signal, -2
        ]

        data?.forEach((locationOutlook) => {
            const { location } = locationOutlook
            // Skip if there is no location
            if (!location) return

            const { name: locationName, longitude, latitude } = location
            const decadal_stat = locationOutlook?.stats?.results[0]
            if (!decadal_stat) return
            const { tercile_probabilities: tercileProbabilities } = decadal_stat

            let dir: number | undefined

            if (toggleNewDirectonalityLogic) {
                dir = getDirectionOutlook(tercileProbabilities)
                if (dir && dir !== -2) {
                    dir = getIsMeanInMass(
                        dir,
                        decadal_stat.outlook_mean,
                        decadal_stat.historical
                    )
                }
                if (dir == -2) dir = 2
            } else {
                dir = getLargestTercile(tercileProbabilities)[0]
            }

            // Skip if the direction cannot be determined
            if (dir == null) return

            const idx = dir + 1
            // Skip if the direction is not defined in the object
            if (!locationName || longitude == null || latitude == null) return

            // Add the location centroid to its corresponding direction
            _categorizedCoordinates[idx]?.push([
                locationName,
                [longitude, latitude],
            ])
        })
        return _categorizedCoordinates
    }, [data, mapboxMap])
    /* STATE < END */

    /* LIFECYCLE HOOKS > START */
    useEffect(() => {
        if (!mapboxMap) return
        if (loading) clearMap(mapboxMap, markers)
        else {
            // Draw pins
            categorizedCoordinates.forEach((locations, idx) => {
                markers.current = markers.current.concat(
                    paintPins({
                        pinElement: pinElements[idx],
                        mapboxMap,
                        locations,
                    })
                )
            })

            // Include all displayed locations in the bounds
            let bounds = new mapboxgl.LngLatBounds([-180, -90], [180, 90])
            const categorizedCoordinatesArr = categorizedCoordinates.flat()
            if (categorizedCoordinatesArr.length > 0) {
                bounds = new mapboxgl.LngLatBounds()
                categorizedCoordinatesArr.forEach(([, coords]) => {
                    bounds.extend(coords)
                })
            }
            mapboxMap.fitBounds(bounds, { padding: 50 })
        }
    }, [categorizedCoordinates, mapboxMap, loading])
    /* LIFECYCLE HOOKS < END */
    return (
        <div className="relative w-full h-full">
            <div
                ref={mapRef}
                className="w-full h-full min-w-[320px] min-h-[320px] rounded-sm">
                {mapboxMap && <CustomMapControls map={mapboxMap} />}
                <DirectionalPinsMapLegend title={title} />
            </div>
        </div>
    )
}

export default DirectionalPinsMap
