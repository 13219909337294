import { createContext, ReactNode, useContext, useMemo } from "react"
import { useAuth } from "./AuthProvider"
import { IHazardVariable } from "../types"
import configureMeasurements, {
  allMeasures,
  AllMeasures,
  AllMeasuresSystems,
  AllMeasuresUnits,
} from "convert-units"

export interface IUnitsConfig {
  units_imperial: string
  units_metric: string
}

const convert = configureMeasurements<
  AllMeasures,
  AllMeasuresSystems,
  AllMeasuresUnits
>(allMeasures)

export interface IUnitConversion {
  convertUnits: (
    value: number,
    variable: IUnitsConfig,
    direction: "convertUnits" | "resetUnits",
  ) => number
  getUnit: (variable: IHazardVariable) => string
  unitName: "metric" | "imperial" // Explicitly typing as a union of the literals
}

export const UnitConversionContext = createContext({} as IUnitConversion)

export const useUnits = () => useContext(UnitConversionContext)

function UnitConversionProvider({ children }: { children: ReactNode }) {
  const { user } = useAuth()

  // Typing unitName explicitly as "metric" or "imperial"
  const unitName: "metric" | "imperial" =
    user?.unit_type === true ? "metric" : "imperial"

  const normalizeUnit = (unitName: string) => {
    return unitName.replace(/[°%]/, "")
  }

  function convertUnits(
    value: number,
    variable: IUnitsConfig,
    direction: "convertUnits" | "resetUnits" = "convertUnits",
  ) {
    if (!user || !variable) return NaN
    try {
      const user_units = normalizeUnit(
        user.unit_type ? variable.units_metric : variable.units_imperial,
      )
      const source_units = normalizeUnit(variable.units_metric)
      const from_units =
        direction === "convertUnits" ? source_units : user_units
      const to_units = direction === "convertUnits" ? user_units : source_units
      const result = convert(value)
        .from(from_units as AllMeasuresUnits)
        .to(to_units as AllMeasuresUnits)
      return result
    } catch (err) {
      return value
    }
  }

  const getUnit = (variable: IHazardVariable) => {
    if (!user || !variable?.units_metric || !variable?.units_imperial)
      return "N/A"
    return user.unit_type ? variable.units_metric : variable.units_imperial
  }

  // Memoizing the context value
  const contextValue = useMemo(
    () => ({
      convertUnits,
      getUnit,
      unitName,
    }),
    [user, unitName],
  )

  return (
    <UnitConversionContext.Provider value={contextValue}>
      {children}
    </UnitConversionContext.Provider>
  )
}

export default UnitConversionProvider
