import { datadogRum } from "@datadog/browser-rum"
import { useFlagsmith } from "flagsmith/react"
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useMutation, useQuery } from "react-query"
import { useNavigate } from "react-router-dom"
import { IPrimitivesDictionary } from "../climateui/types"
import { isValidResponse } from "../climateui/utils/http"
import { SelectWithImage } from "../components"
import { useMemoQuery } from "../hooks"
import { IAccount, IFullAccountUserRelationship } from "../types"
import {
  accountConfigsGET,
  accountSearchPOST,
  accountSwitchPOST,
  userAccountRelationGET,
} from "../utils/networking"
import { useAuth } from "./AuthProvider"

interface IAccountConfig {
  account_id: string
  config_type: string
  config_value: string
  id: string
}

export interface IAccountContext {
  selectedAccount?: string // Account ID
  prevSelectedAccount?: string // Previously selected account
  selectedAccountLogo: ReactNode
  setSelectedAccount: (accountID: string) => void
  accountOptions: IPrimitivesDictionary
  accountsObject: Record<string, IAccount>
  hasPermissions: (permission: string[]) => boolean
  accountPermissions: string[]
  loadingAccountInfo: boolean
  maxLocationsAllowed: number
}
const AccountContext = createContext<IAccountContext>({} as IAccountContext)

// Permission type strings
export const DATA_VARIABLE_PERMISSION = "data_variable"
export const ASSET_PERMISSION = "asset"

export function AccountSelect() {
  const {
    selectedAccount,
    selectedAccountLogo,
    setSelectedAccount,
    accountOptions,
  } = useContext(AccountContext)

  return (
    <SelectWithImage
      image={selectedAccountLogo}
      selected={selectedAccount}
      options={accountOptions}
      setSelected={(selected) => setSelectedAccount(selected as string)}
      textAlignClass="text-left"
      leftRightClass="left-0"
      placeholder="Select account"
    />
  )
}
export const useAccount = () => useContext(AccountContext)

function AccountProvider({ children }: { children: ReactNode[] }) {
  const auth = useAuth()
  const flagsmith = useFlagsmith()
  const navigate = useNavigate()

  const { mutateAsync: switchAccount } = useMutation(accountSwitchPOST)

  const [selectedAccount, setSelectedAccount] = useState<string>()
  const [prevSelectedAccount, setPrevSelectedAccount] = useState<string>()
  const [selectedAccountLogo, setSelectedAccountLogo] = useState<ReactNode>()

  const [maxLocationsAllowed, setMaxLocationsAllowed] = useState<number>(5)

  const [userAccountsIDs] = useMemoQuery<string[]>(
    ["userRelation", auth.user],
    () => userAccountRelationGET(auth.user?.id ?? ""),
    {
      enabled: !!auth.user,
    },
    (data) =>
      (data as IFullAccountUserRelationship[]).reduce(
        (prev: string[], curr: IFullAccountUserRelationship) => [
          ...prev,
          curr.account_id,
        ],
        [],
      ),
    [],
  )
  const [accountsData] = useMemoQuery<IAccount[]>(
    ["accounts", auth.user, userAccountsIDs],
    () =>
      accountSearchPOST({
        filter_by: {
          and: [
            {
              field_name: "account.Account.id",
              operator: "in",
              field_value: JSON.stringify(userAccountsIDs),
            },
            {
              field_name: "account.Account.status",
              operator: "eq",
              field_value: "live",
            },
          ],
        },
        pagination: {
          all: true,
        },
      }),
    {
      enabled: !!auth.user && userAccountsIDs.length > 0,
    },
    (data) => {
      const accounts = (data as { data: IAccount[] }).data
      return accounts
    },
    [],
  )

  const { whiteLabelAccountLogo } = window.dashboard

  const { accountOptions, accountsObject } = useMemo(() => {
    const result = {
      accountOptions: {} as Record<string, string>,
      accountsObject: {} as Record<string, IAccount>,
    }
    // If the user is not defined, exit
    if (!auth.user) {
      return result
    }
    accountsData.forEach((acc) => {
      // Only store accounts that the current user has relationships with
      Object.keys(auth.roles).forEach(() => {
        if (auth.roles[acc.id]) {
          result.accountsObject[acc.id] = acc
          result.accountOptions[acc.id] = acc.name
          acc.children?.forEach((childAccount) => {
            // Add child accounts to the account object to retrieve information
            // BUT not to the options list (no role found with user yet)
            if (childAccount.status === "live")
              result.accountsObject[childAccount.id] = childAccount
          })
        }
      })
    })
    return result
  }, [auth.user, accountsData])

  const handleSetSelectedAccount = (accountID: string) => {
    switchAccount(accountID).then((res) => {
      if (!isValidResponse(res)) return
      auth.setTokenFromResponse(res)
      const prevSelectedAccount = localStorage.getItem(
        "climateai-insights-last-selected-account",
      )
      localStorage.setItem(
        "climateai-insights-last-selected-account",
        accountID,
      )
      setPrevSelectedAccount(selectedAccount)
      if (accountsObject[accountID]?.name)
        flagsmith.identify(accountsObject[accountID].name).finally(() => {
          setSelectedAccount(accountID)
          // This line is here to make sure we navigate to "/" and then to
          // the first allowed route after we get the latest featureFlags info
          if (prevSelectedAccount && prevSelectedAccount !== accountID)
            navigate("/")
        })
    })
  }

  useEffect(() => {
    if (selectedAccount !== undefined) return

    const lastUsedAccountID =
      localStorage.getItem("climateai-insights-last-selected-account") ??
      auth.lastUsedAccountID

    if (lastUsedAccountID && Object.keys(accountsObject).length > 0) {
      if (accountsObject[lastUsedAccountID]) {
        handleSetSelectedAccount(lastUsedAccountID)
      } else {
        handleSetSelectedAccount(Object.keys(accountsObject)[0])
      }
    }
  }, [accountsObject])

  const { data: accountConfigsResponse, isLoading } = useQuery(
    ["accountConfigs", selectedAccount],
    accountConfigsGET,
    {
      enabled: selectedAccount !== undefined,
    },
  )

  const { accountPermissions = [], calculatingAccountInfo = true } =
    useMemo(() => {
      const accountPermissions: string[] = []
      let calculatingAccountInfo = true

      if (isValidResponse(accountConfigsResponse)) {
        const accountConfigs = accountConfigsResponse.data || []
        accountConfigs.forEach((element: IAccountConfig) => {
          // TODO @fran to remove this in the future for asset_permission
          if (
            [DATA_VARIABLE_PERMISSION, ASSET_PERMISSION].includes(
              element.config_type,
            )
          )
            return

          // max locations allowed config
          if (element.config_type === "max_locations_allowed") {
            setMaxLocationsAllowed(+element.config_value)
            return
          }

          // any other needed custom configs
          // -------------------------------

          // view configs
          // TODO algo mal, "true" value and "config_type" prop are misleading
          // and will get changed in the future
          if (element.config_value === "true") {
            accountPermissions.push(element.config_type)
          }
        })
        calculatingAccountInfo = false
      }

      return {
        accountPermissions,
        calculatingAccountInfo,
      }
    }, [accountConfigsResponse])

  const hasPermissions = useCallback(
    (permissions: string[]) =>
      permissions.every(
        (permission) => accountPermissions.indexOf(permission) !== -1,
      ),
    [accountPermissions],
  )

  useEffect(() => {
    if (selectedAccount && Object.keys(accountsObject).length > 0) {
      let backgroundImage = accountsObject[selectedAccount].logo_url
      if (!backgroundImage) backgroundImage = whiteLabelAccountLogo
      if (!backgroundImage)
        backgroundImage = "/images/climateai/ClimateAi-icon.png"
      setSelectedAccountLogo(
        accountsObject[selectedAccount].logo_url ? (
          <div className="h-[34px] w-[34px] bg-transparent rounded-sm -ml-1 flex flex-row items-center overflow-hidden">
            <img
              className="w-full h-auto"
              alt="backgroundImage"
              src={backgroundImage}
            />
          </div>
        ) : null,
      )

      if (!auth.user) return
      // Datadog identify user account
      datadogRum.setUser({
        id: auth.user.id,
        name: auth.user.username,
        email: auth.user.email,
        account: selectedAccount,
        account_name: accountsObject[selectedAccount].name,
      })
    }
  }, [selectedAccount, accountsObject])

  const providerValue = useMemo(() => {
    const loadingAccountInfo = isLoading || calculatingAccountInfo
    return {
      selectedAccount,
      prevSelectedAccount,
      selectedAccountLogo,
      setSelectedAccount: handleSetSelectedAccount,
      accountOptions,
      accountsObject,
      hasPermissions,
      accountPermissions: [...accountPermissions],
      loadingAccountInfo,
      maxLocationsAllowed,
    }
  }, [
    selectedAccount,
    prevSelectedAccount,
    selectedAccountLogo,
    handleSetSelectedAccount,
    accountOptions,
    accountsObject,
    hasPermissions,
    accountPermissions,
    isLoading,
    calculatingAccountInfo,
    maxLocationsAllowed,
  ])

  return (
    <AccountContext.Provider value={providerValue}>
      {children}
    </AccountContext.Provider>
  )
}
export default AccountProvider
