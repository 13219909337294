import React, { useContext } from "react"
import { IAlert, ILocationEssentials, IRiskProfile } from "../../../../types"
import { useTranslate } from "@tolgee/react"
import { sortByDate, handleNavigateToDashboard, Scope } from "../utils"
import { CancelIcon, ClimateTrendsIcon } from "../../../../climateui/icons"

import RiskProfileDetailCard from "./RiskProfileDetailCard"
import { alertCategoriesEnum } from "./mapUtils"
import { useNavigate } from "react-router-dom"
import { Button } from "../../../../climateui/components"
import { useRiskSettingData } from "../../../../hooks/useRiskSettingStageRiskProfileQuery"
import { useRiskSettingAssetData } from "../../../../hooks/useStageRiskProfileAssetDataQuery"
import { UnitConversionContext } from "../../../../providers/UnitConversionProvider"

export interface IPanelContent {
  alert: IAlert
  riskProfile: IRiskProfile
  location: ILocationEssentials
  riskSettingId: string
}

interface ISidePanelProps {
  isPanelOpen?: boolean
  panelContent: IPanelContent[]
  closePanel?: () => void
  selectedCategory?: string | null
  scope?: Scope
}

const SidePanel: React.FC<ISidePanelProps> = ({
  isPanelOpen,
  closePanel,
  selectedCategory,
  scope,
  panelContent,
}) => {
  const { t } = useTranslate()
  const navigate = useNavigate()
  const { convertUnits, getUnit } = useContext(UnitConversionContext)

  const { data: riskSettingData } = useRiskSettingData(
    panelContent.map((d) => d.riskSettingId),
  )
  const { data: assetData, isLoading: assetDataLoading } =
    useRiskSettingAssetData(riskSettingData)

  const filteredContent: IPanelContent[] = panelContent.filter(
    ({ riskProfile }: { riskProfile: IRiskProfile | undefined }) =>
      riskProfile !== undefined,
  )

  filteredContent.sort(sortByDate)

  return (
    <>
      <div className="bg-white border-b border-gray-14 mx-4 py-2 shrink-0">
        <div className="flex justify-between">
          <h1 className="title-sm text-left text-gray-90">
            {panelContent[0].location.name}
          </h1>
          <button
            onClick={closePanel}
            className="w-[23px] h-[23px] cursor-pointer">
            <CancelIcon fill="#666D74" />
          </button>
        </div>
      </div>
      <div className="mx-4 overflow-y-scroll flex-grow">
        <div
          className={`w-full flex flex-wrap ${
            isPanelOpen ? "block" : "hidden"
          }`}>
          {filteredContent.map((card) => {
            return (
              <div
                key={card.alert.id}
                className="border-b border-gray-14 h-full w-full ">
                <RiskProfileDetailCard
                  t={t}
                  riskProfileInfo={card}
                  alertCategory={selectedCategory as alertCategoriesEnum}
                  convertUnits={convertUnits}
                  getUnit={getUnit}
                  riskProfileSettingsAssetData={assetData}
                  riskSettingAssetDataLoading={assetDataLoading}
                  key={`sidepanel_card_${card.alert.id}`}
                />
              </div>
            )
          })}
        </div>
      </div>
      <div className="shrink-0 bg-white border-gray-14 border-t bottom-0 py-3 w-full">
        <div className="flex justify-center items-center mx-4">
          <Button
            icon={<ClimateTrendsIcon fill={"#FFFFFF"} />}
            extend={true}
            disabled={false}
            label={t("goToForecast", "See Forecast")}
            customClasses="bg-[#F06000] text-white border-[#F06000]"
            onClick={() => {
              if (navigate)
                handleNavigateToDashboard(panelContent, navigate, scope)
            }}
          />
        </div>
      </div>
    </>
  )
}

export default SidePanel
