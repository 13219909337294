import { useMemo } from "react"
import LoadingAnimation from "../../../../climateui/components/LoadingAnimation"
import { IRiskCountryOverviewModel } from "../types"
import RiskOutlookBarChart, { Plot } from "./RiskOutlookBarChart"
import { getHazardConfig } from "../riskOutlookUtils"

export interface RiskScoreChartProps {
    data: IRiskCountryOverviewModel[]
    loading: boolean
    isHistorical: boolean
    lowerBound?: number
    upperBound?: number
}

const getBarColor = (value: number, lowerBound: number, upperBound: number) => {
    if (value >= upperBound) {
        return "#E42437" // High risk - Red
    } else if (value >= lowerBound) {
        return "#FDB600" // Medium risk - Yellow
    } else {
        return "#1A8431" // Low risk - Green
    }
}

export function RiskScoreChart({ ...props }: Readonly<RiskScoreChartProps>) {
    const {
        data,
        loading,
        isHistorical,
        lowerBound = 70,
        upperBound = 90,
        ...rest
    } = props

    const chartData = useMemo(() => {
        if (!data?.[0]?.default_geography?.hazards) {
            return { plot: [] }
        }

        const hazards = data[0].default_geography.hazards
        const result: { plot: Plot[] } = {
            plot: [
                {
                    id: "risk-score-forecast",
                    visualization: "bar",
                    points: hazards.map((hazard) => {
                        const { hazard_key, hazard_name } = hazard
                        const value =
                            hazard.risk_outlook_seasonal_stats.plot[0]
                                ?.lastValue?.y1

                        return {
                            label: getHazardConfig(hazard_key, hazard_name)
                                .title,
                            value: value,
                            color: isHistorical
                                ? "#004957"
                                : getBarColor(value, lowerBound, upperBound),
                            ...hazard.risk_outlook_seasonal_stats?.plot?.[0]
                                ?.lastValue,
                        }
                    }),
                },
                {
                    id: "risk-score-past-events",
                    visualization: "bar",
                    points: isHistorical
                        ? hazards.map((hazard) => {
                              const { hazard_key, hazard_name } = hazard
                              const value =
                                  hazard.risk_outlook_seasonal_stats.plot?.[0]
                                      ?.lastValue?.y ?? 0

                              return {
                                  label: getHazardConfig(
                                      hazard_key,
                                      hazard_name
                                  ).title,
                                  value: value,
                                  color: "#4CBFAD",
                                  meta: hazard.risk_outlook_seasonal_stats
                                      .plot?.[0]?.lastValue,
                              }
                          })
                        : [],
                },
            ],
        }
        return result
    }, [data, isHistorical])

    if (loading) {
        return (
            <div className="flex justify-center mt-5 body-lg">
                <LoadingAnimation />
            </div>
        )
    }

    return (
        <RiskOutlookBarChart
            data={chartData}
            title="Risk level by hazard"
            domain={[0, 100]}
            legends={
                isHistorical
                    ? [
                          {
                              type: "dot",
                              label: "Past events",
                              color: "#4CBFAD",
                          },
                          {
                              type: "dot",
                              label: "Forecast events",
                              color: "#004957",
                          },
                      ]
                    : [
                          {
                              type: "dot",
                              label: "Risk level",
                              color: "#BF1728",
                          },
                      ]
            }
            {...rest}
        />
    )
}
