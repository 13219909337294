import { Row } from "@tanstack/react-table"
import {
    IPlannedRisk,
    IRiskProfile,
    IStage,
    ITimelineStage,
    TIMELINE_EDITION_ACTIONS,
} from "../../../types"
import { daysBetween } from "../../../utils"

import Translate from "../../Translate"
import { useTimelineRow } from "./TimelineRow"
import { MultiSelectFilter } from "../../../climateui/components"
import { useTranslate } from "@tolgee/react"
import { StageRiskProfileCell } from "../../SeasonalCalendar/components/editingStageTableUtils"
import { compareDates } from "../../../utils/dates"

export const NEW_STAGE_DURATION = 14
export const MIN_STAGE_DURATION = 0

export function sortRisksByDate(riskA: IPlannedRisk, riskB: IPlannedRisk) {
    // First criterion: Risk hazard variable
    if (!riskA.start_date || !riskB.start_date) return 0
    return riskA.start_date < riskB.start_date ? -1 : 1
}

export function getRiskProfileExistsOrNotSortingFunction(
    existingRiskProfilesObj: Record<string, IRiskProfile>
) {
    return (a: IRiskProfile, b: IRiskProfile) => {
        if (!existingRiskProfilesObj[a.id] && !existingRiskProfilesObj[b.id])
            return 0
        else if (!existingRiskProfilesObj[a.id]) return -1
        else if (!existingRiskProfilesObj[b.id]) return 1
        return 0
    }
}

// Not used anymore - Product's decision. Leaving here in case they retract.
export function sortRiskArraysByFirstRiskStartDate(
    riskArrayA: IPlannedRisk[],
    riskArrayB: IPlannedRisk[]
) {
    // First criterion: Risk hazard variable
    if (!riskArrayA[0].start_date || !riskArrayB[0].start_date) return 0
    return riskArrayA[0].start_date < riskArrayB[0].start_date ? -1 : 1
}

export const moveItemDates = (
    item: IPlannedRisk | IStage,
    daysMoved: number
) => {
    const start = item.start_date ?? new Date()
    const end = item.end_date ?? new Date()
    start.setDate(start.getDate() + daysMoved)
    end.setDate(end.getDate() + daysMoved)
    item.start_date = start
    item.end_date = end
    item.action = item.action ?? TIMELINE_EDITION_ACTIONS.EDITED
}

export function moveStartDate(
    daysFromTimelineStart: number,
    daysMoved: number,
    leastVal: number | null,
    pxPerDay: number,
    setPxFromTimelineStart: (px: number) => void
) {
    let newLeft = daysFromTimelineStart + daysMoved
    if (leastVal !== null) newLeft = newLeft > leastVal ? newLeft : leastVal
    setPxFromTimelineStart(newLeft * pxPerDay)
}
export function moveDragAuxDate(
    stageStartDate: string,
    timelineStartDate: Date,
    daysMoved: number,
    leastVal: number | null,
    setDragAuxDate: (date: Date) => void
) {
    const stageDate = new Date(stageStartDate)
    stageDate.setDate(stageDate.getDate() + daysMoved)
    if (
        leastVal !== null &&
        daysBetween(timelineStartDate, stageDate) < leastVal
    )
        return
    setDragAuxDate(stageDate)
}

function RiskProfileStagesCell({
    riskProfile,
}: {
    readonly riskProfile: IRiskProfile
}) {
    const { t } = useTranslate()
    const { stagesOptions, selectedStagesPerRP, setSelectedStagesPerRP } =
        useTimelineRow()

    return (
        <MultiSelectFilter
            filterName={t("stages", "Stages")}
            placeholder={t("linkStage", "Link Stage")}
            searchPlaceholder={t("search", "Search")}
            options={stagesOptions}
            selected={selectedStagesPerRP[riskProfile.id]}
            setSelected={(selected) =>
                setSelectedStagesPerRP((prev) => ({
                    ...prev,
                    [riskProfile.id]: selected,
                }))
            }
        />
    )
}

export const columns = [
    {
        id: "riskProfile",
        header: () => <Translate labelKey="riskProfiles" />,
        cell: ({ row }: { row: Row<IRiskProfile> }) => (
            <StageRiskProfileCell
                riskProfile={row.original}
                inverse
            />
        ),
    },
    {
        id: "stages",
        header: () => <Translate labelKey="stages" />,
        cell: ({ row }: { row: Row<IRiskProfile> }) => (
            <RiskProfileStagesCell riskProfile={row.original} />
        ),
    },
    {
        accessorKey: "labels",
        filterFn: (
            row: Row<IRiskProfile>,
            _: string,
            filterValue: string[]
        ) => {
            if (filterValue.length === 0) return true
            if (!row.original.labels) return false
            for (const label of row.original.labels) {
                let labelID = ""
                if (typeof label === "string") labelID = label
                else labelID = label.id || ""
                if (filterValue.includes(labelID)) return true
            }
            return false
        },
    },
    {
        accessorKey: "variable",
        filterFn: (
            row: Row<IRiskProfile>,
            columnsId: string,
            filterValue: string
        ) => {
            if (filterValue === "") return true
            for (const hazard_profile of row.original.hazard_profiles) {
                const variable = hazard_profile.hazard_variable
                if (filterValue === variable.id) return true
            }
            return false
        },
    },
    {
        accessorKey: "name",
        cell: ({ row }: { row: Row<IRiskProfile> }) => {
            return row.original.name
        },
    },
]

function areStagesOverlapping(stage1: ITimelineStage, stage2: ITimelineStage) {
    const stage1StartDate = stage1.start_date
    const stage1EndDate = new Date(
        stage1StartDate.getFullYear(),
        stage1StartDate.getMonth(),
        stage1StartDate.getDate() + stage1.duration
    )
    return stage2.start_date < stage1EndDate
}

export function wrapStage(
    stagesMatrix: ITimelineStage[][],
    stage: ITimelineStage,
    level: number
): void {
    const row = stagesMatrix[level]
    if (!row) {
        stagesMatrix[level] = [stage]
        return
    }
    const lastRowElement = row[row.length - 1]
    if (
        areStagesOverlapping(lastRowElement, stage) ||
        stage.isOverflowing ||
        lastRowElement.isOverflowing
    )
        return wrapStage(stagesMatrix, stage, level + 1)
    row.push(stage)
}

export const isStageOverflowingYear = (
    globalStartDate: Date,
    stage: ITimelineStage
) => {
    const globalEndDate = new Date(globalStartDate)
    globalEndDate.setFullYear(globalEndDate.getFullYear() + 1)

    if (compareDates(stage.start_date, globalStartDate) === -1) {
        // if stage is in the past
        return -1
    } else {
        const stageEndDate = new Date(stage.start_date)
        stageEndDate.setDate(stageEndDate.getDate() + stage.duration)
        if (compareDates(stageEndDate, globalEndDate) === 1) {
            // if stage is in the future
            return 1
        }
    }

    return 0
}

// negative value means stage is closer to the start of the year
export const closerToStartOrEndOfYear = (
    startDate: Date,
    stage: ITimelineStage
) => {
    const endDate = new Date(startDate)
    endDate.setFullYear(endDate.getFullYear() + 1)

    return (
        daysBetween(startDate, stage.start_date) -
        (daysBetween(stage.start_date, endDate) - stage.duration)
    )
}
