import { useCallback, useState } from "react"
import ChevronIcon from "../../../../climateui/icons/ChevronArrow"
import { TooltipV2 } from "../../../../climateui/components"
import { useTranslate } from "@tolgee/react"
import { useSearchParams } from "react-router-dom"
import { SingleDropdownSelect } from "../../../../climateui/components/Inputs"
import { CalendarIcon } from "../../../../climateui/icons"
import { Scope } from "../utils"

interface IAlertDateNavigatorProps {
  onChange?: (timeResolution: string) => void
  defaultMode?: Scope
  isLoading?: boolean
  setScope?: (scope: Scope) => void
}

const AlertDateNavigator: React.FC<IAlertDateNavigatorProps> = ({
  onChange,
  defaultMode = 0, // Default to Scope.DAILY
  setScope,
}) => {
  const { t } = useTranslate()
  const [queryParams, setQueryParams] = useSearchParams()
  const [timeScope, setTimeScope] = useState<Scope>(defaultMode)

  const handleSelectMode = useCallback(
    (newSelected: unknown) => {
      const newMode = Number.parseInt(newSelected as string) as Scope
      if (!isNaN(newMode)) {
        setTimeScope(newMode)
        setScope?.(newMode)
        if (onChange) {
          onChange(Scope[newMode]) // Pass the time resolution string
        }
        queryParams.set("scope", Scope[newMode])
        setQueryParams(queryParams)
      }
    },
    [setScope, onChange, queryParams, setQueryParams],
  )

  // Navigate date range forward or backward
  const navigateDate = (direction: number) => {
    const newMode = ((timeScope + direction + 3) % 3) as Scope
    if (!isNaN(newMode)) {
      setTimeScope(newMode)
      setScope?.(newMode)
      if (onChange) {
        onChange(Scope[newMode])
      }
      queryParams.set("scope", Scope[newMode])
      setQueryParams(queryParams)
    }
  }

  return (
    <TooltipV2
      content={Scope[timeScope]}
      position="top">
      <div className="flex items-center bg-white border rounded-sm gap-1 border-gray-14">
        <div className="flex items-center mx-1">
          {/* BACK ARROW */}
          <button
            onClick={() => {
              navigateDate(-1)
            }}
            className="w-5 h-5 cursor-pointer transform rotate-90">
            <ChevronIcon fill="#666D74" />
          </button>
          {/* FORWARD ARROW */}
          <button
            onClick={() => {
              navigateDate(1)
            }}
            className="w-5 h-5 cursor-pointer transform -rotate-90">
            <ChevronIcon fill="#666D74" />
          </button>
        </div>
        <SingleDropdownSelect
          options={{
            [Scope.daily]: t("daily"),
            [Scope.weekly]: t("weekly"),
            [Scope.monthly]: t("monthly"),
          }}
          selected={timeScope.toString()}
          setSelected={handleSelectMode}
          trailingIcon={<CalendarIcon />}
        />
      </div>
    </TooltipV2>
  )
}

export default AlertDateNavigator
