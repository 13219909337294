import { useMemo } from "react"
import { StringParam, useQueryParam, withDefault } from "use-query-params"
import { IAssetModel } from "../../types"

export const yieldUnitTranslationKeys: Record<string, string> = {
  t_ha: "tonsHectare",
  bu_acre: "bushelsAcre",
  cwt_acre: "hundredweightAcre",
  lbs_acre: "poundsAcre",
}

const useYieldUnits = (
  assetModels: IAssetModel[],
  selectedRegion: string,
  assetId: string,
  isLoading: boolean,
  translateFn: (key: string, fallback?: string) => string = (key, fallback) =>
    fallback ?? key,
) => {
  const unitOptions = useMemo(() => {
    // generate unit options here after
    // assets are done loading
    const currentCountryModel = assetModels?.find(
      (am) => am.region_id === selectedRegion && am.asset_id === assetId,
    )

    // country units should be the same for their states
    let countryModelUnit = currentCountryModel?.unit_conversion?.unit ?? "NA"
    countryModelUnit = countryModelUnit.replace("/", "_")

    // create units options (only t/he) in case units === "NA"
    const unitOptions: Record<string, string> = {
      t_ha: translateFn("tonsHectare"),
    }

    if (countryModelUnit !== "NA")
      unitOptions[countryModelUnit] = translateFn(
        yieldUnitTranslationKeys[countryModelUnit],
      )

    return unitOptions
  }, [assetModels, selectedRegion, assetId, isLoading])

  const unitParam = withDefault(StringParam, Object.keys(unitOptions)[0]) // t_ha
  const [units, setUnits] = useQueryParam("units", unitParam)

  return { unitOptions, units: units.replace("_", "/"), setUnits }
}

export default useYieldUnits
